import {
  Box,
  BoxProps,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { CompletionIndicator } from '../CompletionIndicator';
import {
  ActionStatus,
  IAction,
  IActionSubtask,
  IActionSubtaskWithStatus,
} from 'types/types';
import {
  RiArrowLeftCircleFill,
  RiCalendarLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiExternalLinkLine,
  RiFireFill,
  RiUserLine,
} from 'react-icons/ri';
import { ToggleButton } from '../ToggleButton';
import { ReactComponent as PinFill } from '../RiskRegisterItem/assets/pin_fill.svg';
import { ReactComponent as PinLine } from '../RiskRegisterItem/assets/pin_line.svg';
import { IconSquare } from '../IconSquare';
import moment from 'moment';
import { DashboardActionSubtasks } from '../DashboardActionSubtasks';
import key from 'weak-key';
import { OnClickAction } from 'utils/useOnlickActions';
import { Link } from 'react-router-dom';
import { formatParsedISO } from 'utils/formatParsedISO';

interface Props extends BoxProps {
  action: IAction;
  onCheck?: (subtask_id?: number) => void;
  onclickAction?: (payload: OnClickAction) => void;
  completing?: boolean;
  mode: 'list' | 'item';
  onUpdateAction: (payload: {
    action_id: number | string;
    subtask_id?: number | string;
    clearSubtasks?: boolean;
    status: ActionStatus;
  }) => void;
}

export function DashboardActionDetail(props: Props) {
  const {
    onUpdateAction,
    action,
    onCheck,
    completing,
    mode,
    onclickAction,
    sx,
  } = props;
  const theme = useTheme();

  const archived = action.status !== 'pending';

  const subtasksWithStatuses: IActionSubtaskWithStatus[] =
    action.action_definition.subtasks.map(item => {
      // use subtask_statuses to assign status if set
      const status: ActionStatus =
        (action.subtask_statuses || {})[`subtask-${item.id}`] || 'pending';
      return { ...item, status };
    });

  const actionCompletion = useMemo(() => {
    if (action.status === 'completed') return 100;
    return Math.round(
      subtasksWithStatuses?.length
        ? (subtasksWithStatuses.filter(item => item.status === 'completed')
            .length /
            subtasksWithStatuses.length) *
            100
        : 0,
    );
  }, [completing, subtasksWithStatuses]);

  return (
    <>
      <Box
        sx={{
          pt: 2,
          pb: 3,
          ...sx,
        }}
      >
        {mode === 'item' && (
          <Box sx={{ pb: 4 }}>
            <Button
              startIcon={<RiArrowLeftCircleFill />}
              variant="text"
              component={Link}
              to={'../'}
              sx={{ px: 0 }}
            >
              Back to Actions list
            </Button>
          </Box>
        )}
        <Stack spacing={4}>
          {/* first row */}
          <Box>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={{xs: 2, md:3}}>
                  {/* {!!action.action_definition.priority && (
                    <Stack
                      direction={'row'}
                      spacing={0.5}
                      alignItems={'center'}
                    >
                      <IconSquare
                        sx={{
                          background: theme.palette.error.main,
                          color: 'common.white',
                          borderColor: `${theme.palette.common.white} !important`,
                        }}
                        size={30}
                        icon={<RiFireFill />}
                      />
                      <Typography variant="h5">Priority</Typography>
                    </Stack>
                  )} */}
                  {!!action.due_at && (
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <RiCalendarLine
                        size={20}
                        color={theme.palette.primary.main}
                      />
                      <Typography variant="h5">Due by:</Typography>
                      <Typography variant="body2">
                        {moment(action.due_at).format('D MMM Y')}
                      </Typography>
                    </Stack>
                  )}
                  {!!action.completed_at && (
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <RiCalendarLine
                        size={20}
                        color={theme.palette.primary.main}
                      />
                      <Typography variant="h5">Completed at:</Typography>
                      <Typography variant="body2">
                        {moment(action.completed_at).format('D MMM Y [at] H:mm')}
                      </Typography>
                    </Stack>
                  )}{' '}
                  {!!action.dismissed_at && (
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <RiCalendarLine
                        size={20}
                        color={theme.palette.primary.main}
                      />
                      <Typography variant="h5">Dismissed at:</Typography>
                      <Typography variant="body2">
                        {moment(action.dismissed_at).format('D MMM Y')}
                      </Typography>
                    </Stack>
                  )}
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <RiUserLine size={20} color={theme.palette.primary.main} />
                    <Typography variant="h5">From:</Typography>
                    <Typography>
                      {action.action_definition.from_name}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Box>
          {/* second row */}
          <Stack sx={{ backgroundColor: theme.palette.common.beige, p: 3 }}>
            <Box>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {mode === 'list' && (
                  <Box>
                    <CompletionIndicator
                      percentage={actionCompletion}
                      thickness={10}
                      size={85}
                      circleColours={{
                        progressColour: 'primary.main',
                        remainderColour: 'primary.light',
                      }}
                    >
                      <Typography variant="body2" fontWeight={700}>
                        {actionCompletion}%
                      </Typography>
                    </CompletionIndicator>
                  </Box>
                )}
                <Typography variant="h4">
                  {action.action_definition.name}
                </Typography>
              </Stack>
            </Box>
            <Box>
              {!!action.action_definition.onclick && (
                <>
                  <Stack direction={'row'} alignItems={'center'}>
                    {/* <Typography fontWeight={'bold'}>Action destination:</Typography> */}
                    <Button
                      sx={{ px: 0 }}
                      endIcon={
                        action.action_definition.onclick ===
                          'open-external-link' && <RiExternalLinkLine />
                      }
                      variant="text"
                      onClick={e =>
                        onclickAction({
                          id: action.action_definition.onclick,
                          params: action.action_definition.onclick_parameters,
                        } as OnClickAction)
                      }
                    >
                      Visit link
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>
          {mode === 'item' && (
            <>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Box>
                  <CompletionIndicator
                    percentage={actionCompletion}
                    thickness={10}
                    size={85}
                    circleColours={{
                      progressColour: 'primary.main',
                      remainderColour: 'primary.light',
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight={700}
                    component={'span'}
                  >
                    {actionCompletion}%
                  </Typography>
                  <Typography variant="body2" component={'span'} pl={1}>
                    {formatParsedISO(action.updated_at, 'd MMMM Y HH:mm')}
                  </Typography>
                </Box>
              </Stack>
            </>
          )}

          {/* third row */}
          {!!action.action_definition.description && (
            <>
              <Stack>
                <Typography variant="h5">Summary</Typography>
                <Box>
                  <Stack spacing={1}>
                    {action.action_definition.description
                      .split('\n')
                      .map((i: string) => (
                        <Typography variant="body2">{i}</Typography>
                      ))}
                  </Stack>
                </Box>
              </Stack>
            </>
          )}

          {/* Subtasks */}
          {!!action.action_definition.subtasks.length && (
            <>
              <Stack>
                <Typography variant="h5">Sub-tasks</Typography>
                <DashboardActionSubtasks
                  onclickAction={onclickAction}
                  onCheck={(subtask_id, checked) =>
                    archived
                      ? null
                      : onUpdateAction({
                          action_id: action.id,
                          status: checked ? 'completed' : 'pending',
                          subtask_id,
                        })
                  }
                  subtasks={subtasksWithStatuses}
                />
              </Stack>
            </>
          )}

          {/* Buttons grid */}
          {(mode === 'item' || mode === 'list') && (
            <Box>
              {archived && (
                <Grid
                  container
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() =>
                        onUpdateAction({
                          action_id: action.id,
                          status: 'pending',
                        })
                      }
                    >
                      Restore this action
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!archived && (
                <Grid
                  container
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() =>
                        onUpdateAction({
                          action_id: action.id,
                          status: 'dismissed',
                        })
                      }
                    >
                      Dismiss
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      disabled={
                        !!subtasksWithStatuses &&
                        subtasksWithStatuses.some(
                          st =>
                            st.status === 'pending' ||
                            st.status === 'dismissed',
                        )
                      }
                      onClick={() =>
                        onUpdateAction({
                          action_id: action.id,
                          status: 'completed',
                        })
                      }
                    >
                      Mark as completed
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
}
