import React, { ReactNode } from 'react';
import { Box, BoxProps, Stack, SxProps, Typography } from '@mui/material';

interface Props extends Pick<BoxProps, 'sx'> {
  title: string;
  children: ReactNode;
  sx?: SxProps;
}

export function DashboardBox(props: Props) {
  const { title, children, sx, ...rProps } = props;
  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'common.grey',
        px: 2,
        pt: 2,
        pb: 4,
        ...sx,
      }}
      {...rProps}
    >
      <Stack spacing={1}>
        <Box sx={{ mb: 2 }}>
          <Typography variant={'h5'}>{title}</Typography>
        </Box>
        <>{children}</>
      </Stack>
    </Box>
  );
}
