import { Stack } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { IOrganisation } from 'types/types';
import { DashboardActionDetail } from 'app/components/DashboardActionDetail';
import { useDispatch, useSelector } from 'react-redux';
import { useActionSectionSlice } from './slice/hook';
import { selectActionsSection } from './slice/selectors';
import { useEffectOnce } from 'usehooks-ts';
import { useParams } from 'react-router';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { useOnClickActions } from 'utils/useOnlickActions';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const { organisation } = props;
  const { id } = useParams<{ id: string }>();
  const { actions } = useActionSectionSlice();
  const dispatch = useDispatch();
  const { loadAction, updateAction } = useSelector(selectActionsSection);
const { doAction } = useOnClickActions({ organisation_id: organisation.id });
  const load = () => {
    dispatch(
      actions.loadActionRequest({
        id,
        organisation_id: organisation.id,
      }),
    );
  };

  useEffectOnce(() => {
    dispatch(actions.loadActionInit());
    load();
  });

  useEffect(() => {
    load();
  }, [id]);

  useHandleApiResponse(updateAction, 'Action updated', {
    onSuccess: () =>
      dispatch(
        actions.loadActionRequest({
          id,
          organisation_id: organisation.id,
        }),
      ),
  });

  if (!loadAction.data) return <LoadingIndicator />;

  return (
    <>
      <Stack spacing={6}>
        {!!loadAction.data && (
          <DashboardActionDetail
            onUpdateAction={payload =>
              dispatch(actions.updateActionRequest(payload))
            }
            mode="item"
            action={loadAction.data}
            onclickAction={doAction}
          />
        )}
      </Stack>
    </>
  );
}
