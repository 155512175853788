import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MeetingsState } from './types';
import {
  getApiCallInitialState,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { IAbridgedMeeting, IMeeting } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: MeetingsState = {
  loadMeetings: getApiCallInitialState<IAbridgedMeeting[]>('list'),
  loadMeeting: getApiCallInitialState<IMeeting | undefined>(),
  shareMeeting: getApiCallInitialState(),
  deleteMeeting: getApiCallInitialState(),
  saveDocument: uploadApiCallInitialState,
  deleteDocument: getApiCallInitialState(),
  reorderDocuments: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    loadMeetingsInit(state, action: PayloadAction<void>) {
      state.loadMeetings = initialState.loadMeetings;
    },
    loadMeetingsRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.loadMeetings.loading = true;
      state.loadMeetings.error = undefined;
      state.loadMeetings.touched = true;
    },
    loadMeetingsSuccess(
      state,
      action: PayloadAction<{ data: IAbridgedMeeting[] }>,
    ) {
      state.loadMeetings.loading = false;
      state.loadMeetings.error = undefined;
      state.loadMeetings.data = action.payload.data;
    },
    loadMeetingsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMeetings.loading = false;
      state.loadMeetings.error = action.payload;
    },
    loadMeetingInit(state, action: PayloadAction<void>) {
      state.loadMeeting = initialState.loadMeeting;
    },
    loadMeetingRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadMeeting.loading = true;
      state.loadMeeting.error = undefined;
    },
    loadMeetingSuccess(state, action: PayloadAction<{ data: IMeeting }>) {
      state.loadMeeting.loading = false;
      state.loadMeeting.error = undefined;
      state.loadMeeting.data = action.payload.data;
    },
    loadMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMeeting.loading = false;
      state.loadMeeting.error = action.payload;
    },
    shareMeetingRequest(state, action: PayloadAction<{ id: number | string }>) {
      state.shareMeeting.loading = true;
      state.shareMeeting.error = undefined;
    },
    shareMeetingSuccess(state, action: PayloadAction<{ data: IMeeting }>) {
      state.shareMeeting.loading = false;
      state.shareMeeting.error = undefined;
      state.shareMeeting.data = action.payload.data;
    },
    shareMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.shareMeeting.loading = false;
      state.shareMeeting.error = action.payload;
    },
    deleteMeetingRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.deleteMeeting.loading = true;
      state.deleteMeeting.error = undefined;
    },
    deleteMeetingSuccess(state, action: PayloadAction<any>) {
      state.deleteMeeting.loading = false;
      state.deleteMeeting.error = undefined;
      state.deleteMeeting.data = action.payload.data;
    },
    deleteMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteMeeting.loading = false;
      state.deleteMeeting.error = action.payload;
    },
    saveDocumentRequest(state, action: PayloadAction<any>) {
      state.saveDocument.loading = true;
      state.saveDocument.error = undefined;
    },
    saveDocumentSuccess(state, action: PayloadAction<any>) {
      state.saveDocument.loading = false;
      state.saveDocument.error = undefined;
      state.saveDocument.data = action.payload;
    },
    saveDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveDocument.loading = false;
      state.saveDocument.error = action.payload;
    },
    saveDocumentProgress(state, action: PayloadAction<{ progress: number }>) {
      state.saveDocument.progress = action.payload.progress;
    },
    deleteDocumentRequest(state, action: PayloadAction<any>) {
      state.deleteDocument.loading = true;
      state.deleteDocument.error = undefined;
    },
    deleteDocumentSuccess(state, action: PayloadAction<any>) {
      state.deleteDocument.loading = false;
      state.deleteDocument.error = undefined;
      state.deleteDocument.data = action.payload;
    },
    deleteDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteDocument.loading = false;
      state.deleteDocument.error = action.payload;
    },
    reorderDocumentsRequest(state, action: PayloadAction<any>) {
      state.reorderDocuments.loading = true;
      state.reorderDocuments.error = undefined;
    },
    reorderDocumentsSuccess(state, action: PayloadAction<any>) {
      state.reorderDocuments.loading = false;
      state.reorderDocuments.error = undefined;
      state.reorderDocuments.data = action.payload;
    },
    reorderDocumentsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.reorderDocuments.loading = false;
      state.reorderDocuments.error = action.payload;
    },
  },
});

export const { actions: meetingsActions } = slice;
