/**
 *
 * Item
 *
 */
import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useConfirm } from 'utils/useConfirm';
import { RiAddCircleFill, RiDragDropFill, RiShareLine } from 'react-icons/ri';
import { useMeetingsSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings } from './slice/selectors';
import { IOrganisation } from 'types/types';
import { useParams } from 'react-router';
import { useHasChanged } from 'utils/usePrevious';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import moment from 'moment';
import { ConflictsTable } from 'app/components/ConflictsTable';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from 'app/widgets/MeetingFormWidget';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useNavigate } from 'react-router-dom';
import { AddToCalendar } from 'app/components/AddToCalendar';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { MeetingDocument } from './MeetingDocument';
import { noop } from 'lodash';
import { Edit } from '@mui/icons-material';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import FileUploadComponent from '../../components/FileUpload/FileUploadComponent';
import LinkRenderer from './LinkRenderer';
import { useSnackbar } from 'notistack';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const { organisation } = props;

  const { actions } = useMeetingsSlice();
  const {
    loadMeeting,
    deleteMeeting,
    deleteDocument,
    reorderDocuments,
    saveDocument,
  } = useSelector(selectMeetings);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const { hasPermission } = useHasPermission();

  const { id } = useParams();

  const { open, close, isOpen } = useMeetingFormWidget();

  const loadMeetingLoadingChanged = useHasChanged(loadMeeting.loading);
  const idChanged = useHasChanged(id);

  const deleteDocumentCB = useCallback(
    (documentId: number) => {
      confirm({
        title: 'Delete document',
        description: 'Are you sure?',
      })
        .then(() => {
          dispatch(
            actions.deleteDocumentRequest({ meetingId: +id, documentId }),
          );
        })
        .catch(noop);
    },
    [loadMeeting.data],
  );

  const saveDocumentCB = useCallback(
    (p: {
      name: string;
      file: File;
      description: string;
      documentId?: number;
      category: 'agenda' | 'minutes' | 'previous-minutes' | 'working-paper';
    }) => {
      dispatch(actions.saveDocumentRequest({ meetingId: +id, ...p }));
    },
    [loadMeeting.data],
  );

  const reorderDocumentsCB = useCallback(
    (oldIndex: number, newIndex: number, row: any) => {
      dispatch(
        actions.reorderDocumentsRequest({
          meetingId: +id,
          oldIndex,
          newIndex,
          row,
        }),
      );
    },
    [loadMeeting.data],
  );

  useEffect(() => {
    if (loadMeetingLoadingChanged && !loadMeeting.loading) {
      if (loadMeeting.error) {
        if (loadMeeting.error.code === 401) return;
        navigate('../', { replace: true });
      }
    }

    if (idChanged && id) {
      load();
    }
  });

  const load = useCallback(() => {
    dispatch(actions.loadMeetingRequest({ id: +id }));
  }, [organisation]);

  useEffect(() => {
    load();
    return () => {
      dispatch(actions.loadMeetingInit());
    };
  }, [load]);

  useHandleApiResponse(deleteMeeting, 'Meeting deleted', {
    onSuccess: () => {
      close();
      navigate('../upcoming', { replace: true });
    },
    errorMessage: 'Unable to delete a meeting',
  });

  useHandleApiResponse(saveDocument, 'Document saved', {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(deleteDocument, null, {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(reorderDocuments, null, {
    onSuccess: () => {
      load();
    },
  });

  if (!loadMeeting.data) {
    return <LoadingIndicator />;
  }

  const canManageDocuments = hasPermission(
    'manage-meeting-documents',
    'committee',
    loadMeeting.data.committee_id,
  );
  const canEditMeeting = hasPermission(
    'edit-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const isFutureMeeting = moment(loadMeeting.data.date_start).isAfter(moment());

  const canShareMeeting = hasPermission(
    'share-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const canDeleteMeeting = hasPermission(
    'delete-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  return (
    <>
      <Helmet title={loadMeeting.data.name}>
        <meta name="description" content="Committees within the organisation" />
      </Helmet>

      <Box>
        <Box sx={{ my: 4 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant={'h3'}>{loadMeeting.data.name}</Typography>
            <Box>
              <Stack direction={'row'} spacing={2}>
                {canShareMeeting && (
                  <Button
                    variant="outlined"
                    startIcon={<RiShareLine />}
                    onClick={() =>
                      confirm({
                        title: 'Share meeting',
                        description: (
                          <>
                            This will send a link for the following meeting by
                            email to all members of this Committee who are
                            registered to attend:{' '}
                            <Typography fontWeight={'bold'}>
                              {loadMeeting.data.name}, on{' '}
                              {moment(loadMeeting.data.date_start).format(
                                'ddd D MMMM Y [from] HH:mm',
                              )}{' '}
                              to{' '}
                              {moment(loadMeeting.data.date_end).format(
                                'HH:mm',
                              )}
                            </Typography>
                          </>
                        ),
                        confirmationText: 'Send',
                      })
                        .then(() =>
                          dispatch(
                            actions.shareMeetingRequest({
                              id: loadMeeting.data.id,
                            }),
                          ),
                        )
                        .catch(() => {})
                    }
                  >
                    Share meeting
                  </Button>
                )}

                {canEditMeeting && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      onClick={() =>
                        open({ id, organisation_id: organisation.id })
                      }
                    >
                      Edit meeting
                    </Button>
                    <MeetingFormDialog
                      open={isOpen}
                      onClose={close}
                      onDelete={
                        canDeleteMeeting
                          ? meeting => {
                              confirm({
                                title: 'Delete meeting',
                                description: 'Are you sure?',
                              })
                                .then(() => {
                                  dispatch(
                                    actions.deleteMeetingRequest({
                                      id: meeting.id,
                                    }),
                                  );
                                })
                                .catch(() => {});
                            }
                          : null
                      }
                      onChange={load}
                    />
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Stack sx={{ my: 4 }} spacing={4}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={5}
            alignItems={{ xs: 'start', md: 'center' }}
          >
            <Box>
              <Stack spacing={1}>
                <Typography variant="body2">Date</Typography>
                <Box>
                  <Typography fontWeight={'bold'}>
                    {moment(loadMeeting.data.date_start).format(
                      'ddd DD[th] MMM YYYY',
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={1}>
                <Typography variant="body2">Time</Typography>
                <Box>
                  <Typography fontWeight={'bold'}>
                    {moment(loadMeeting.data.date_start).format('HH:mm')} -{' '}
                    {moment(loadMeeting.data.date_end).format('HH:mm')}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={1}>
                <Typography variant="body2">Committee</Typography>
                <Box>
                  <Typography fontWeight={'bold'}>
                    {loadMeeting.data.committee.name}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
          {loadMeeting.data.description && (
            <Box>
              <Stack spacing={1}>
                <Typography variant="body2">Description</Typography>
                <Box>
                  <Typography fontWeight={'bold'}>
                    {loadMeeting.data.description}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          )}
          <Paper variant={'outlined'} square sx={{ p: 2 }}>
            <Stack spacing={3}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography variant={'h5'}>Location</Typography>
                {isFutureMeeting && (
                  <AddToCalendar
                    calendarView={'responsive'}
                    breakpoint={'md'}
                    buttonProps={{
                      size: 'small',
                    }}
                    calendar_links={loadMeeting.data.calendar_links}
                  />
                )}
              </Stack>
              <Box>
                <Typography>
                  <LinkRenderer
                    location={
                      loadMeeting.data.location
                        ? loadMeeting.data.location
                        : 'Not described'
                    }
                  />
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Stack>
        <Box>
          <Stack spacing={4}>
            <Typography variant={'h3'}>Documents</Typography>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper
                    variant={'outlined'}
                    sx={{
                      p: 2,
                    }}
                    square
                  >
                    <Stack>
                      <Typography variant={'h5'}>Agenda</Typography>
                      <MeetingDocument
                        loading={saveDocument.loading || deleteDocument.loading}
                        canManage={canManageDocuments}
                        initialDocument={loadMeeting.data?.agenda_document}
                        onSave={values =>
                          saveDocumentCB({ ...values, category: 'agenda' })
                        }
                        onDelete={() =>
                          deleteDocumentCB(
                            +loadMeeting.data?.agenda_document.id,
                          )
                        }
                      />
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    square
                    variant={'outlined'}
                    sx={{
                      p: 2,
                    }}
                  >
                    <Stack>
                      <Box>
                        <Typography variant={'h5'}>
                          Minutes{' '}
                          <Box
                            component={'span'}
                            sx={{ color: 'grey.500', fontWeight: 'normal' }}
                          >
                            (Previous meeting)
                          </Box>
                        </Typography>
                      </Box>
                      <MeetingDocument
                        loading={saveDocument.loading || deleteDocument.loading}
                        canManage={canManageDocuments}
                        initialDocument={
                          loadMeeting.data?.previous_minutes_document
                        }
                        onSave={values =>
                          saveDocumentCB({
                            ...values,
                            category: 'previous-minutes',
                          })
                        }
                        onDelete={() =>
                          deleteDocumentCB(
                            +loadMeeting.data?.previous_minutes_document.id,
                          )
                        }
                      />
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Paper
              square
              variant={'outlined'}
              sx={{
                p: 2,
              }}
            >
              <Stack>
                <Box>
                  <Typography variant={'h5'}>Working papers</Typography>
                </Box>
                <Box>
                  {!!loadMeeting.data.documents?.length && (
                    <DataGridPro
                      autoHeight
                      getRowHeight={() => 80}
                      slots={{
                        columnHeaders: React.forwardRef((props, ref) => (
                          <Box ref={ref} />
                        )),
                        noRowsOverlay: () => (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                              }}
                            >
                              <Typography>No documents attached</Typography>
                            </Box>
                          </>
                        ),
                      }}
                      columnVisibilityModel={{
                        actions: canManageDocuments,
                      }}
                      rowReordering={canManageDocuments}
                      onRowOrderChange={({ oldIndex, targetIndex, row }) => {
                        reorderDocumentsCB(oldIndex, targetIndex, row);
                      }}
                      hideFooter
                      columns={[
                        // { field: 'order_by' },
                        {
                          field: 'name',
                          headerName: 'Name',
                          minWidth: 200,
                          flex: 0.8,
                          sortable: false,
                          renderCell: params => (
                            <MeetingDocument
                              loading={
                                saveDocument.loading ||
                                deleteDocument.loading ||
                                reorderDocuments.loading
                              }
                              canManage={canManageDocuments}
                              initialDocument={params.row}
                              onSave={values =>
                                saveDocumentCB({
                                  ...values,
                                  documentId: params.id,
                                  category: 'default',
                                })
                              }
                              onDelete={() => deleteDocumentCB(+params.row.id)}
                            />
                          ),
                        },
                      ]}
                      rows={loadMeeting.data?.documents}
                    />
                  )}
                </Box>
                {canManageDocuments && (
                  <Box
                    sx={{
                      border: 2,
                      borderColor: 'grey.200',
                      borderStyle: 'dashed',
                      borderRadius: 3,
                      backgroundColor: 'common.beige',
                      p: 2,
                    }}
                  >
                    <FileUploadComponent
                      multiple
                      onErrorEvent={error =>
                        snackbar.enqueueSnackbar(error, { variant: 'error' })
                      }
                      loading={saveDocument.loading}
                      handleFileChange={file => {
                        dispatch(
                          actions.saveDocumentRequest({
                            meetingId: +id,
                            file,
                            category: 'default',
                          }),
                        );
                      }}
                    >
                      <Stack spacing={1} alignItems={'center'}>
                        <Button startIcon={<RiAddCircleFill />}>
                          Select a Document to upload
                        </Button>
                        <Typography variant="body2">or</Typography>
                        <Button
                          variant="text"
                          sx={{
                            color: theme => theme.palette.common.black,
                            fontWeight: 'bold',
                          }}
                          startIcon={<RiDragDropFill />}
                        >
                          Drag & drop to upload
                        </Button>
                      </Stack>
                    </FileUploadComponent>
                  </Box>
                )}
              </Stack>
            </Paper>
          </Stack>
        </Box>

        <Box sx={{ my: 4 }}>
          <Stack
            sx={{ my: 3 }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant={'h3'}>Declarations of Interest</Typography>
          </Stack>
          <ConflictsTable conflicts={loadMeeting.data.conflicts} />
        </Box>
        {/*
        <Box>
          <Typography variant="h3" sx={{ pb: 4 }}>
            Meeting logbook
          </Typography>
          <Stack spacing={3}>
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Obcaecati, ab.
              </Typography>
              <Chip
                label={'9:00 AM, 5 MAY 2024'}
                sx={{
                  background: theme => theme.palette.grey[500],
                  color: 'common.white',
                }}
              />
            </Stack>
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Obcaecati, ab.
              </Typography>
              <Chip
                label={'9:00 AM, 5 MAY 2024'}
                sx={{
                  background: theme => theme.palette.grey[500],
                  color: 'common.white',
                }}
              />
            </Stack>
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Obcaecati, ab.
              </Typography>
              <Chip
                label={'9:00 AM, 5 MAY 2024'}
                sx={{
                  background: theme => theme.palette.grey[500],
                  color: 'common.white',
                }}
              />
            </Stack>
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Obcaecati, ab.
              </Typography>
              <Chip
                label={'9:00 AM, 5 MAY 2024'}
                sx={{
                  background: theme => theme.palette.grey[500],
                  color: 'common.white',
                }}
              />
            </Stack>
          </Stack>
        </Box>
        */}
      </Box>
    </>
  );
}
