import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardMedia,
  Grid,
  Link as ExternalLink,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { FeatureCode, features, IOrganisation } from 'types/types';
import { DashboardBox } from 'app/components/DashboardBox';
import { Link, useParams } from 'react-router-dom';
import { DashboardCard } from 'app/components/DashboardCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableOrganisations } from 'app/providers/OrganisationSelector/slice/selectors';
import { organisationSelectorActions } from 'app/providers/OrganisationSelector/slice';
import { useRiskRegisterSectionSlice } from '../RiskRegisterSection/slice/hook';
import { useChecklistsSlice } from '../Checklists/slice/hook';
import { useMeetingsSlice } from '../Meetings/slice/hook';
import { selectMeetings } from '../Meetings/slice/selectors';
import { MeetingsList } from '../Meetings/MeetingsList';
import { selectRiskRegisterSection } from '../RiskRegisterSection/slice/selectors';
import { RiskMatrix } from '../RiskRegisterSection/RiskMatrix';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { CardBody } from 'app/components/CardBody';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import AcademyImage from './assets/academy-thumb.png';
import BoardImage from './assets/board_image.png';
import ReferAFriend from './assets/refer_a_friend.jpg';
import RiskMatrixImage from './assets/risk_matrix.png';
import { ReactComponent as RiskRegisterIcon } from './assets/riskregister_icon.svg';
import { ReactComponent as BoardIcon } from './assets/board_icon.svg';
import { usePartnerDetailsSlice } from '../PartnerDetails/slice/hook';
import { selectPartnerDetails } from '../PartnerDetails/slice/selectors';
import { organisationPageActions } from '../OrganisationPage/slice';
import { useConfirm } from 'utils/useConfirm';
import { noop } from 'utils/noop';
import { Helmet } from 'react-helmet-async';
import { StatusChip } from './StatusChip';
import { RolloverMenu } from './RolloverMenu';
import { TimerSubtitle } from './TimerSubtitle';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { DashboardActionsWidget } from 'app/widgets/DashboardActionsWidget';
import { ExpandMore, Restore } from '@mui/icons-material';
import key from 'weak-key';

interface Props {
  organisation: IOrganisation;
}

export function Dashboard(props: Props) {
  const { actions: meetingsActions } = useMeetingsSlice();
  const { actions: riskRegisterSectionActions } = useRiskRegisterSectionSlice();
  const { actions: checklistsActions } = useChecklistsSlice();
  const { actions: partnerDetailsActions } = usePartnerDetailsSlice();
  const { reloadOrganisation } = useReloadCurrentOrganisation();
  const [initialised, setInitialised] = useState(false);

  const { organisation } = props;
  const dispatch = useDispatch();

  const { loadUser } = useSelector(selectAuthProvider);
  const { loadPartner } = useSelector(selectPartnerDetails);
  const { loadMeetings } = useSelector(selectMeetings);
  const availableOrganisations = useSelector(selectAvailableOrganisations);
  const { loadOrganisationRiskRegister } = useSelector(
    selectRiskRegisterSection,
  );

  const confirm = useConfirm();

  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      loadContent();
    }, 250);
  }, [organisation]);

  const disabledFeatures = useMemo(
    () =>
      Object.keys(organisation.features).filter(
        c => organisation.features[c].disabled,
      ),
    [organisation.features],
  );

  const loadContent = () => {
    if (organisation.risk_register_activated) {
      dispatch(
        riskRegisterSectionActions.loadOrganisationRiskRegisterRequest({
          organisation_id: organisation.id,
        }),
      );
    }
    if (organisation.checklists_activated) {
      dispatch(
        checklistsActions.loadOrganisationChecklistsRequest({
          organisation_id: organisation.id,
        }),
      );
    }
    if (organisation.board_activated) {
      dispatch(
        meetingsActions.loadMeetingsRequest({
          organisation_id: organisation.id,
        }),
      );
    }
    dispatch(
      partnerDetailsActions.loadPartnerRequest({
        organisation_id: organisation.id,
      }),
    );
  };

  const { hasPermission } = useHasPermission();

  const canManageFeatures = hasPermission(
    'manage-features',
    'organisation',
    id,
  );

  const canViewChecklists = hasPermission(
    'view-checklists',
    'organisation',
    id,
  );

  const disableFeature = (featureCode: FeatureCode) => {
    confirm({
      title: `Turn off the ${features[featureCode]} feature?`,
      description: (
        <>
          Clicking 'OK' will hide the feature throughout the app. Your data will
          be retained, and the feature can be easily restored via 'Restore
          features' found at the bottom of the dashboard.
        </>
      ),
    })
      .then(() =>
        dispatch(
          organisationPageActions.manageFeatureRequest({
            id: +organisation.id,
            code: featureCode,
            action: 'disable',
          }),
        ),
      )
      .catch(noop);
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  if (!loadUser.data) return <LoadingIndicator />;

  return (
    <>
      <Helmet title={'Dashboard'}>
        <meta name="description" content="Organisation dashboard" />
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Stack spacing={3}>
              {!!loadUser.data.thinkific_enabled && (
                <Box sx={{ backgroundColor: 'common.beige', p: 4 }}>
                  <Grid container>
                    <Grid item xs={12} sm={6} lg={7}>
                      <Stack
                        spacing={2}
                        justifyContent={'space-between'}
                        sx={{ height: '100%' }}
                      >
                        <Typography color={'common.midnight'}>
                          GOV360 ACADEMY
                        </Typography>
                        {isMd && (
                          <CardMedia
                            component={'img'}
                            src={AcademyImage}
                            sx={{
                              borderRadius: 1,
                              width: 480,
                              height: 480,
                            }}
                          />
                        )}
                        <Box>
                          <Stack spacing={2}>
                            <Typography variant="h2">
                              Build your skills with the Gov360 Academy
                            </Typography>
                            <Typography variant="body2">
                              As part of your plan you have access to learning
                              provided by the Gov360 Academy (topics will be
                              determined by your plan). Browse now for
                              information on how we can help you develop your
                              skills. (CPD accreditation subject to module)
                            </Typography>
                          </Stack>
                        </Box>

                        <Box>
                          <Button
                            fullWidth
                            sx={{ p: 2 }}
                            component={Link}
                            to={'/profile/academy-record'}
                          >
                            View your Academy record now
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                    {!isMd && (
                      <Grid item xs={12} sm={6} lg={5}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <CardMedia
                            component={'img'}
                            src={AcademyImage}
                            sx={{
                              borderRadius: 1,
                              width: 280,
                              height: 280,
                            }}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
              {!organisation.features.board.disabled &&
                organisation.features.board.status !== 'not-allowed' && (
                  <DashboardCard
                    icon={<BoardIcon />}
                    title={'Board Meetings'}
                    chip={
                      <Stack direction={'row'} alignItems={'center'}>
                        <StatusChip feature={organisation.features.board} />
                        {hasPermission(
                          'manage-features',
                          'organisation',
                          organisation.id,
                        ) && (
                          <RolloverMenu
                            options={[
                              {
                                onClick: () => disableFeature('board'),
                                children: <>Turn off this feature</>,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  >
                    <LoadingIndicator
                      showContent={
                        (!loadMeetings.loading && loadMeetings.touched) ||
                        !organisation.features.board.activated
                      }
                    >
                      {loadMeetings.data.length &&
                      organisation.board_activated ? (
                        <>
                          <MeetingsList
                            meetings={loadMeetings.data}
                            pastLimit={2}
                            futureLimit={2}
                          />
                          <Box>
                            <Button
                              component={Link}
                              to={'./meetings'}
                              variant="text"
                              sx={{ px: 0 }}
                            >
                              Go to Board meetings page
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <CardBody
                          title={
                            canManageFeatures
                              ? 'Level up by creating your Board'
                              : 'Attend board meetings securely'
                          }
                          subtitle={
                            canManageFeatures ? (
                              <TimerSubtitle
                                title={'5 minutes set up time for Board'}
                              />
                            ) : undefined
                          }
                          image={BoardImage}
                          buttonText={
                            canManageFeatures
                              ? organisation.features.board.activated
                                ? 'Get started with boards'
                                : 'Activate boards'
                              : undefined
                          }
                          buttonProps={
                            organisation.features.board.activated
                              ? { component: Link, to: './meetings' }
                              : { component: Link, to: './activate/board' }
                          }
                          body={
                            <>
                              <Box>
                                <Typography
                                  sx={{
                                    '&::before': { content: '"•"', pr: 1 },
                                  }}
                                  variant="body2"
                                >
                                  {canManageFeatures
                                    ? 'Effortlessly manage your meetings with secured document uploads for your meeting pack and seamless scheduling of committee meetings through preferred video-call software.'
                                    : 'Access your meeting materials securely. Seamlessly integrate meeting schedules with your calendar, and join video-call sessions with ease, enhancing your readiness and engagement.'}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    '&::before': { content: '"•"', pr: 1 },
                                  }}
                                  variant="body2"
                                >
                                  {canManageFeatures
                                    ? 'Proactively distribute meeting packs among board members, fostering alignment and ensuring a unified understanding.'
                                    : 'Receive meeting packs promptly, enabling you to align with fellow participants and grasp the agenda fully.'}
                                </Typography>
                              </Box>
                            </>
                          }
                        />
                      )}
                    </LoadingIndicator>
                  </DashboardCard>
                )}

              {!organisation.features.actions.disabled &&
                !!hasPermission(
                  'view-dashboard-actions',
                  'organisation',
                  organisation.id,
                ) && (
                  <DashboardActionsWidget
                    organisation={organisation}
                    chip={
                      <Stack direction={'row'} alignItems={'center'}>
                        <StatusChip feature={organisation.features.board} />
                        {hasPermission(
                          'manage-features',
                          'organisation',
                          organisation.id,
                        ) && (
                          <RolloverMenu
                            options={[
                              {
                                onClick: () => disableFeature('actions'),
                                children: <>Turn off this feature</>,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  />
                )}

              {!organisation.features['risk-register'].disabled &&
                organisation.features['risk-register'].status !==
                  'not-allowed' && (
                  <DashboardCard
                    icon={<RiskRegisterIcon />}
                    title={'Risk Register'}
                    chip={
                      <Stack direction={'row'} alignItems={'center'}>
                        <StatusChip
                          feature={organisation.features['risk-register']}
                        />
                        {hasPermission(
                          'manage-features',
                          'organisation',
                          organisation.id,
                        ) && (
                          <RolloverMenu
                            options={[
                              {
                                onClick: () => disableFeature('risk-register'),
                                children: <>Turn off this feature</>,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  >
                    {organisation.risk_register_activated ? (
                      <>
                        <LoadingIndicator
                          showContent={!!loadOrganisationRiskRegister.data}
                        >
                          <RiskMatrix
                            riskRegister={loadOrganisationRiskRegister.data}
                            urlPrefix={'./risk-register/risk-matrix/'}
                          />
                        </LoadingIndicator>
                        <Box>
                          <Button
                            component={Link}
                            to={'./risk-register'}
                            variant="text"
                            sx={{ px: 0 }}
                          >
                            Browse your Risk Register in full
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <CardBody
                        title={
                          canManageFeatures
                            ? 'Set up your Risk Register'
                            : "Review your Organisation's Risk Register"
                        }
                        subtitle={
                          canManageFeatures ? (
                            <TimerSubtitle
                              title={
                                'Takes less than 4 minutes to start your Risk Register'
                              }
                            />
                          ) : undefined
                        }
                        image={RiskMatrixImage}
                        buttonText={
                          canManageFeatures
                            ? 'Set up your Risk register now'
                            : undefined
                        }
                        buttonProps={{
                          component: Link,
                          to: './risk-register',
                        }}
                        body={
                          <>
                            <Box>
                              <Typography
                                sx={{
                                  '&::before': { content: '"•"', pr: 1 },
                                }}
                                variant="body2"
                              >
                                {canManageFeatures ? (
                                  <>
                                    Use our simple risk register tool to help
                                    you document, analyse and report your key
                                    organisational risks to all board members.
                                  </>
                                ) : (
                                  <>
                                    Our simple risk register tool helps you and
                                    your Board document, analyse and report your
                                    key organisational risks once activated by
                                    your Board administrator.
                                  </>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  '&::before': { content: '"•"', pr: 1 },
                                }}
                                variant="body2"
                              >
                                {canManageFeatures ? (
                                  <>
                                    Accessible 24x7, move away from excel files
                                    and paper to a live, updatable register
                                    which only takes minutes to create and use.
                                  </>
                                ) : (
                                  <>
                                    Accessible 24x7 (although only your
                                    Administrator can amend and update the
                                    data), move away from excel files and paper
                                    and view your risks in real-time here.
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                    )}
                  </DashboardCard>
                )}
              {/*
              {canManageFeatures &&
                !organisation.features.checklists.disabled &&
                organisation.features.checklists.status !== 'not-allowed' && (
                  <DashboardCard
                    icon={<ChecklistIcon />}
                    title={'Compliance Checklists'}
                    chip={
                      <Stack direction={'row'} alignItems={'center'}>
                        <StatusChip
                          feature={organisation.features.checklists}
                        />
                        {hasPermission(
                          'manage-features',
                          'organisation',
                          organisation.id,
                        ) && (
                          <RolloverMenu
                            options={[
                              {
                                onClick: () => disableFeature('checklists'),
                                children: <>Turn off this feature</>,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  >
                    {organisation.checklists_activated ? (
                      <>
                        <LoadingIndicator
                          showContent={
                            loadOrganisationChecklists.data.filter(
                              cl => cl.status === 'active',
                            ).length > 0
                          }
                        >
                          <Box py={2}>
                            <DashboardChecklists
                              checklists={loadOrganisationChecklists.data.filter(
                                cl => cl.status === 'active',
                              )}
                            />
                          </Box>
                        </LoadingIndicator>
                        <Box>
                          <Button
                            component={Link}
                            to={'./checklists'}
                            variant="text"
                            sx={{  px: 0 }}
                          >
                            Review progress in Compliance Checklists
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <CardBody
                        title={
                          canManageFeatures
                            ? 'Get started with Compliance Checklists'
                            : 'Review your Organisational Checklists'
                        }
                        subtitle={
                          canManageFeatures ? (
                            <TimerSubtitle
                              title={
                                'Most users take less than 5 minutes to finish their first checklist'
                              }
                            />
                          ) : undefined
                        }
                        buttonText={
                          canViewChecklists
                            ? 'Try out Compliance checklists now'
                            : undefined
                        }
                        buttonProps={{
                          component: Link,
                          to: './checklists',
                        }}
                        image={ChecklistsImage}
                        body={
                          <>
                            <Box>
                              <Typography
                                sx={{
                                  '&::before': { content: '"•"', pr: 1 },
                                }}
                                variant="body2"
                              >
                                {canManageFeatures ? (
                                  <>
                                    Our feature includes a series of simple
                                    checklists (subject to Plan) that help you
                                    document your key actions each month as a
                                    board administrator.
                                  </>
                                ) : (
                                  <>
                                    As part of Governance360, your Board
                                    administrator has access to a series of
                                    simple checklists (subject to Plan) that
                                    help document their key actions each month
                                    as a board administrator.
                                  </>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  '&::before': { content: '"•"', pr: 1 },
                                }}
                                variant="body2"
                              >
                                {canManageFeatures ? (
                                  <>
                                    Typically no more than 10 questions, keep on
                                    top of basic administration each month, save
                                    and/or export your results for all to see.
                                  </>
                                ) : (
                                  <>
                                    As a board member, you can review (but not
                                    edit) their outputs, helping you keep in
                                    touch with key information relevant to your
                                    role.
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                    )}
                  </DashboardCard>
                )} /*/}
              {!!disabledFeatures.length &&
                hasPermission(
                  'manage-features',
                  'organisation',
                  organisation.id,
                ) && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant={'h5'}>Restore features</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ py: 0 }}>
                      <MenuList>
                        {disabledFeatures.map(code => (
                          <MenuItem
                            fullWidth
                            divider
                            key={key({ restore: code })}
                            component={Button}
                            endIcon={<Restore />}
                            onClick={() =>
                              confirm({ title: 'Restore feature?' })
                                .then(() =>
                                  dispatch(
                                    organisationPageActions.manageFeatureRequest(
                                      {
                                        id: +organisation.id,
                                        code: code as FeatureCode,
                                        action: 'enable',
                                      },
                                    ),
                                  ),
                                )
                                .catch(noop)
                            }
                          >
                            Restore {features[code]}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}
            </Stack>
          </Grid>
          {/* dashboard right column */}
          <Grid item xs={12} md={3}>
            <Stack spacing={2}>
              <DashboardBox title={organisation.name}>
                <Stack spacing={2}>
                  <Stack spacing={0}>
                    {organisation.board_activated &&
                      hasPermission('view-meetings', 'organisation', id) && (
                        <Box>
                          <Button
                            component={Link}
                            variant="text"
                            to={'./meetings'}
                            sx={{ p: 0 }}
                          >
                            Meetings
                          </Button>
                        </Box>
                      )}

                    {hasPermission('view-actions-page', 'organisation', id) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./actions'}
                          sx={{ p: 0 }}
                        >
                          Actions
                        </Button>
                      </Box>
                    )}
                    {hasPermission(
                      'view-organisation-reports',
                      'organisation',
                      id,
                    ) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./reports'}
                          sx={{ p: 0 }}
                        >
                          Reports
                        </Button>
                      </Box>
                    )}
                    {hasPermission(
                      'view-organisation-documents',
                      'organisation',
                      id,
                    ) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./documents/reference'}
                          sx={{ p: 0 }}
                        >
                          Documents
                        </Button>
                      </Box>
                    )}

                    {organisation.risk_register_activated &&
                      hasPermission(
                        'view-risk-register',
                        'organisation',
                        id,
                      ) && (
                        <Box>
                          <Button
                            component={Link}
                            variant="text"
                            to={'./risk-register'}
                            sx={{ p: 0 }}
                          >
                            Risk Register
                          </Button>
                        </Box>
                      )}
                    {organisation.checklists_activated &&
                      hasPermission('view-checklists', 'organisation', id) && (
                        <Box>
                          <Button
                            component={Link}
                            variant="text"
                            to={'./checklists'}
                            sx={{ p: 0 }}
                          >
                            Checklists
                          </Button>
                        </Box>
                      )}
                    {hasPermission(
                      'view-committees-list',
                      'organisation',
                      id,
                    ) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./committees'}
                          sx={{ p: 0 }}
                        >
                          Committees
                        </Button>
                      </Box>
                    )}
                    {hasPermission(
                      'view-organisation-members',
                      'organisation',
                      id,
                    ) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./members'}
                          sx={{ p: 0 }}
                        >
                          Members
                        </Button>
                      </Box>
                    )}

                    {hasPermission(
                      'view-organisation-profile',
                      'organisation',
                      id,
                    ) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./organisation-profile'}
                          sx={{ p: 0 }}
                        >
                          Organisation profile
                        </Button>
                      </Box>
                    )}
                    {hasPermission(
                      'manage-academy-seats',
                      'organisation',
                      id,
                    ) && (
                      <Box>
                        <Button
                          component={Link}
                          variant="text"
                          to={'./academy-seats'}
                          sx={{ p: 0 }}
                        >
                          Academy seats
                        </Button>
                      </Box>
                    )}
                  </Stack>
                  {availableOrganisations.length > 1 && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        dispatch(
                          organisationSelectorActions.setOrganisationSelectOpen(
                            true,
                          ),
                        )
                      }
                    >
                      Switch organisation
                    </Button>
                  )}
                </Stack>
              </DashboardBox>

              <ExternalLink
                href="https://www.governance360.com/refer-a-friend"
                target="_blank"
                rel="noopener"
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
              >
                <Stack spacing={0}>
                  <CardMedia
                    component={'img'}
                    src={ReferAFriend}
                    alt=""
                    sx={{
                      height: 'auto',
                      width: '100%',
                      objectFit: 'contain',
                      borderRadius: '8px 8px 0 0',
                    }}
                  />
                  <DashboardBox title="Refer a friend" sx={{ borderTop: 0 }}>
                    <Typography color={'common.black'}>
                      Invite friends, and get rewards! Refer now for exclusive
                      offers
                    </Typography>
                  </DashboardBox>
                </Stack>
              </ExternalLink>

              <DashboardBox
                title="Brought to you by"
                sx={{
                  borderTop: 15,
                  borderTopColor: 'common.midnight',
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              >
                <LoadingIndicator showContent={!!loadPartner.data}>
                  {!!loadPartner.data && (
                    <>
                      <Box
                        sx={{
                          py: 2,
                        }}
                      >
                        {!!loadPartner.data.logo_image ? (
                          <CardMedia
                            component={'img'}
                            src={loadPartner.data.logo_image}
                            alt={loadPartner.data.name}
                            sx={{
                              height: 120,
                              width: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        ) : (
                          <Typography variant={'h5'}>
                            {loadPartner.data.name}
                          </Typography>
                        )}
                      </Box>
                      <Stack spacing={1}>
                        <Typography>
                          Current plan: {organisation.plan_name}
                        </Typography>
                        <Box>
                          <Button
                            variant="text"
                            component={ExternalLink}
                            href={loadPartner.data.contact_details.website}
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                              p: 0,

                              color: 'primary.main',
                              fontWeight: 600,
                            }}
                          >
                            Visit the {loadPartner.data.name} portal
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            component={Link}
                            variant="text"
                            to={'./partner-details'}
                            sx={{
                              p: 0,

                              lineHeight: 1.2,
                            }}
                          >
                            Contact your Development Manager
                          </Button>
                        </Box>
                      </Stack>
                    </>
                  )}
                </LoadingIndicator>
              </DashboardBox>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
