/**
 *
 * ActionsSection
 *
 */
import * as React from 'react';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useActionSectionSlice } from '../slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectActionsSection } from '../slice/selectors';
import { Form } from './Form';
import { Box } from '@mui/material';
import { useEffectOnce } from 'usehooks-ts';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { useNavigate } from 'react-router-dom';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const { organisation } = props;
  const navigate = useNavigate();
  const { actions } = useActionSectionSlice();
  const dispatch = useDispatch();
  const {
    loadActionDefinition,
    loadActionDefinitionOptions,
    updateActionDefinition,
    createActionDefinition,
  } = useSelector(selectActionsSection);

  const { id } = useParams<{
    id: string;
  }>();

  const load = () => {
    dispatch(
      actions.loadActionDefinitionOptionsRequest({
        organisation_id: organisation.id,
      }),
    );
    if (!id || id === 'new') return;
    dispatch(
      actions.loadActionDefinitionRequest({
        id,
        organisation_id: organisation.id,
      }),
    );
  };

  useEffect(() => {
    if (id === 'new') {
      dispatch(actions.loadActionDefinitionInit());
    }
    load();
  }, [id]);

  useHandleApiResponse(loadActionDefinition, null, {
    errorMessage: 'Action not found',
    onError: () => {
      navigate('..');
    },
  });
  useHandleApiResponse(createActionDefinition, 'Action created', {
    onSuccess: () => {
      navigate(`./${createActionDefinition.data?.id}`);
    },
  });

  useHandleApiResponse(updateActionDefinition, 'Action updated', {
    onSuccess: () => {
      navigate('..');
    },
  });

  return (
    <>
      <Helmet title={'Manage Action'}>
        <meta
          name="description"
          content="Manage your own actions on this page"
        />
      </Helmet>
      <Box>
        <Form
          item={loadActionDefinition.data}
          onSubmit={values => {
            if (id === 'new') {
              dispatch(
                actions.createActionDefinitionRequest({
                  organisation_id: organisation.id,
                  data: values,
                }),
              );
            } else {
              dispatch(
                actions.updateActionDefinitionRequest({
                  organisation_id: organisation.id,
                  id,
                  data: values,
                }),
              );
            }
          }}
          options={loadActionDefinitionOptions.data}
        />
      </Box>
    </>
  );
}
