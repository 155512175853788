/**
 *
 * ReportsPage
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { IOrganisation } from 'types/types';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useReportsPageSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectReportsPage } from './slice/selectors';
import { SectionHeader } from 'app/components/SectionHeader';
import { ReportTableBlock } from 'app/components/ReportTableBlock';
import { DataAccordion } from 'app/components/DataAccordion';
import moment from 'moment';

interface Props {
  organisation: IOrganisation;
}

export function ReportsPage(props: Props) {
  const { organisation } = props;
  const { actions } = useReportsPageSlice();
  const {
    loadDocuments,
    loadConflicts,
    loadMembers,
    loadMemberUsersSecurity,
    loadMembersInCommittees,
  } = useSelector(selectReportsPage);
  const dispatch = useDispatch();
  const [open, setOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const updatedDate = useMemo(() => {
    return moment().format('DD/MM/YYYY HH:mm:ss');
  }, [
    loadDocuments,
    loadConflicts,
    loadMembers,
    loadMemberUsersSecurity,
    loadMembersInCommittees,
  ]);

  const load = (name?: string) => {
    if (name === 'documents') {
      setOpen({ ...open, documents: true });
      dispatch(
        actions.loadDocumentsRequest({ organisation_id: +organisation.id }),
      );
    }
    if (name === 'conflicts') {
      setOpen({ ...open, conflicts: true });
      dispatch(
        actions.loadConflictsRequest({ organisation_id: +organisation.id }),
      );
    }
    if (name === 'members') {
      setOpen({ ...open, members: true });
      dispatch(
        actions.loadMembersRequest({ organisation_id: +organisation.id }),
      );
    }
    if (name === 'security') {
      setOpen({ ...open, security: true });
      dispatch(
        actions.loadMemberUsersSecurityRequest({
          organisation_id: +organisation.id,
        }),
      );
    }
    if (name === 'committees') {
      setOpen({ ...open, committees: true });
      dispatch(
        actions.loadMembersInCommitteesRequest({
          organisation_id: +organisation.id,
        }),
      );
    }
  };

  useEffect(() => {}, [organisation]);

  return (
    <>
      <SectionHeader title="Reports" />
      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={4}>
            <Box>
              <Stack spacing={3}>
                <Typography variant="h3">Report Writer</Typography>
                <Typography>
                  Here are a series of reports that you can administer,
                  providing you with insights and information on your
                  Organisation and its use. You can also export the reports for
                  use outside of Governance360. Only users with Admin
                  permissions can see this reporting section - any members of
                  your Organisation without Administration rights will not be
                  able to view this section
                </Typography>
              </Stack>
            </Box>

            <ReportTableBlock
              title="Security & activity"
              totalReports={1}
              dataAccordion={
                <>
                  <DataAccordion
                    onToggle={() =>
                      setOpen({ ...open, security: !open.security })
                    }
                    apiCall={loadMemberUsersSecurity}
                    open={!!open.security}
                    onUpdate={() => load('security')}
                    title="User logins & MFA status"
                    description="This report shows how many users have 2FA (Two factor authentication) enabled against those who have not. Users without 2FA enabled are listed in full, allowing you to action a campaign to encourage them to enable 2FA. Last login activity is also shown."
                    updated_at={loadMemberUsersSecurity.data.updated_at}
                  />
                </>
              }
            />

            <ReportTableBlock
              title="Members"
              totalReports={3}
              dataAccordion={
                <>
                  <DataAccordion
                    onToggle={() =>
                      setOpen({ ...open, members: !open.members })
                    }
                    apiCall={loadMembers}
                    open={!!open.members}
                    onUpdate={() => load('members')}
                    title="Member details"
                    updated_at={loadMembers.data.updated_at}
                    description="All members of the organisation are listed here. You can see their name, email address, role."
                  />
                  <DataAccordion
                    onToggle={() =>
                      setOpen({ ...open, committees: !open.committees })
                    }
                    apiCall={loadMembersInCommittees}
                    open={!!open.committees}
                    onUpdate={() => load('committees')}
                    title="Committee membership"
                    updated_at={loadMembersInCommittees.data.updated_at}
                    description="Commmittee membership for all members of the organisation."
                  />
                  <DataAccordion
                    onToggle={() =>
                      setOpen({ ...open, conflicts: !open.conflicts })
                    }
                    apiCall={loadConflicts}
                    open={!!open.conflicts}
                    onUpdate={() => load('conflicts')}
                    title="Declarations of Interest"
                    updated_at={loadConflicts.data.updated_at}
                    description="Understand any declarations of interests that have been submitted by board members."
                  />
                </>
              }
            />

            <ReportTableBlock
              title="Documents"
              totalReports={1}
              dataAccordion={
                <>
                  <DataAccordion
                    onToggle={() =>
                      setOpen({ ...open, documents: !open.documents })
                    }
                    apiCall={loadDocuments}
                    open={!!open.documents}
                    onUpdate={() => load('documents')}
                    title="Document details"
                    updated_at={loadDocuments.data.updated_at}
                    description="All documents uploaded to the organisation are listed here. "
                  />
                </>
              }
            />
          </Stack>
        </Box>
      </Container>
    </>
  );
}
