import { Stack, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { IOrganisation } from 'types/types';
import { DashboardActionCategoryHeader } from 'app/components/DashboardActionCategoryHeader';
import { DashboardActionDetail } from 'app/components/DashboardActionDetail';
import { useDispatch, useSelector } from 'react-redux';
import { useActionSectionSlice } from './slice/hook';
import { selectActionsSection } from './slice/selectors';
import { useEffectOnce } from 'usehooks-ts';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { useOnClickActions } from 'utils/useOnlickActions';
import { LoadingIndicator } from '../../components/LoadingIndicator';

interface Props {
  organisation: IOrganisation;
}

export function Completed(props: Props) {
  const { organisation } = props;

  const { actions } = useActionSectionSlice();
  const dispatch = useDispatch();
  const { loadArchivedActions, updateAction } =
    useSelector(selectActionsSection);

  const load = () =>
    dispatch(
      actions.loadArchivedActionsRequest({
        organisation_id: organisation.id,
      }),
    );
  useEffectOnce(() => {
    load();
  });

  useHandleApiResponse(updateAction, 'Action updated', {
    onSuccess: () => load(),
  });
  const { doAction } = useOnClickActions({ organisation_id: organisation.id });
  const filterButton = {
    borderColor: 'grey.400',
    borderWidth: 1,
    px: 4,
    py: 2,
    borderRadius: 0.5,
    '&:hover': {
      borderWidth: 1,
    },
  };

  if(loadArchivedActions.touched && !loadArchivedActions.loading) return <LoadingIndicator/>

  return (
    <>
      <Stack spacing={6}>
        <DashboardActionCategoryHeader
          title="All closed actions"
          numberOfActions={loadArchivedActions.data.length}
        />
        {!!loadArchivedActions.data.length ? (
          <>
            {loadArchivedActions.data.map(action => (
              <Fragment key={action.id}>
                <DashboardActionDetail
                  onclickAction={doAction}
                  onUpdateAction={payload =>
                    dispatch(actions.updateActionRequest(payload))
                  }
                  sx={{
                    border: theme => `1px solid ${theme.palette.common.grey}`,
                    px: 2,
                  }}
                  mode="list"
                  action={action}
                />
              </Fragment>
            ))}
          </>
        ) : (
          <>
            <Typography>You have no archived actions</Typography>
          </>
        )}
      </Stack>
    </>
  );
}
