/**
 *
 * Checklists
 *
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ChecklistSubmission,
  IChecklistSetting,
  IOrganisation,
} from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { useChecklistsSlice } from './slice/hook';
import { Box, IconButton, Stack, Switch, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffectOnce } from 'utils/useEffectOnce';
import { selectChecklists } from './slice/selectors';
import { useHasChanged } from 'utils/usePrevious';
import { isEqual } from 'lodash';
import { useDebounce } from 'utils/useDebounce';
import { formatParsedISO } from 'utils/formatParsedISO';
import { RiArrowDropDownFill, RiCalendarLine } from 'react-icons/ri';
import { SubmissionsDialog } from './SubmisionsDialog';
import { DueDateDialog } from './DueDateDialog';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';

interface Props {
  organisation: IOrganisation;
}

export function List(props: Props) {
  const [dueDateDialogId, setDueDateDialogId] = useState<number | null>(null);
  const { reloadOrganisation } = useReloadCurrentOrganisation();

  const [dialogContent, setDialogContent] = useState<Array<
    Partial<ChecklistSubmission>
  > | null>(null);

  const { organisation } = props;
  const dispatch = useDispatch();
  const { actions } = useChecklistsSlice();
  const { loadOrganisationChecklists, loadChecklistSettings } =
    useSelector(selectChecklists);
  const [newSettings, setNewSettings] = useState<IChecklistSetting[]>(
    loadChecklistSettings.data ?? [],
  );

  const getChecklistStatus = id =>
    (newSettings || []).find(cl => cl.id === id) || {
      id,
      active: false,
      due_date: null,
    };

  const update = (id, settings: { active?: boolean; due_date?: string }) => {
    setNewSettings(
      loadOrganisationChecklists.data
        .map(cl => ({
          ...getChecklistStatus(cl.id),
          ...(cl.id === id ? settings : {}),
        }))
        .filter(cls => cls.active),
    );
  };

  const load = () => {
    dispatch(
      actions.loadOrganisationChecklistsRequest({
        organisation_id: organisation.id,
      }),
    );
    dispatch(
      actions.loadChecklistSettingsRequest({
        organisation_id: organisation.id,
      }),
    );
  };

  const settingsDataChanged = useHasChanged(loadChecklistSettings.data);

  const newSettingsDebounced = useDebounce(newSettings, 1000);
  const newSettingsChanged = useHasChanged(newSettingsDebounced);

  useEffect(() => {
    if (
      newSettingsChanged &&
      !isEqual(newSettings, loadChecklistSettings.data)
    ) {
      dispatch(
        actions.updateChecklistSettingsRequest({
          organisation_id: organisation.id,
          checklists: newSettings,
        }),
      );
    }
    if (settingsDataChanged && loadChecklistSettings.data) {
      setNewSettings(loadChecklistSettings.data);
      reloadOrganisation();
    }
  });

  useEffectOnce(() => {
    load();
  });

  return (
    <Box sx={{ my: 4 }}>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <Typography variant={'h3'}>Compliance checklists</Typography>
          <Typography variant={'body1'}>
            View details related to previous or scheduled checklists. Schedule
            new checklists.
          </Typography>
        </Stack>
        <Box
          sx={{
            minHeight: 300,
            height: 'calc(100vh - 400px)',
            width: '100%',
            mb: 4,
          }}
        >
          <DataGridPro
            columns={[
              {
                headerName: 'Name',
                field: 'name',
                renderCell: params => (
                  <Stack spacing={1} sx={{ py: 2 }}>
                    <Typography
                      component={Link}
                      style={{
                        ...(!getChecklistStatus(params.id).active
                          ? { pointerEvents: 'none', color: 'grey' }
                          : {}),
                      }}
                      to={`${params.id}`}
                    >
                      {params.value}
                    </Typography>
                    {!!params.row.description && (
                      <Typography variant={'body2'}>
                        {params.row.description}
                      </Typography>
                    )}
                  </Stack>
                ),
                flex: 0.8,
              },

              {
                field: 'submissions',
                headerName: 'Last submitted',
                renderCell: params => (
                  <>
                    {!!params.value.length && (
                      <Box>
                        {formatParsedISO(params.value[0].submitted_at)}
                        <IconButton
                          edge={'end'}
                          onClick={() => setDialogContent(params.value)}
                        >
                          <RiArrowDropDownFill />
                        </IconButton>
                      </Box>
                    )}
                  </>
                ),
                flex: 0.5,
              },
              {
                field: 'submission',
                headerName: 'Next submission due',
                renderCell: params => {
                  return (
                    <Stack alignItems={'center'} direction={'row'}>
                      <Box>
                        {formatParsedISO(
                          getChecklistStatus(params.id).due_date,
                          undefined,
                          'Not scheduled',
                        )}
                      </Box>
                      <IconButton
                        disabled={!getChecklistStatus(params.id).active}
                        onClick={() => {
                          setDueDateDialogId(+params.id ?? null);
                        }}
                      >
                        <RiCalendarLine />
                      </IconButton>
                    </Stack>
                  );
                },
                flex: 0.5,
              },
              {
                field: 'active',
                renderHeader: () => 'Active',
                renderCell: params => (
                  <Switch
                    color={'success'}
                    checked={getChecklistStatus(params.id).active}
                    onChange={(ev, checked) => {
                      update(params.id, { active: checked });
                    }}
                  />
                ),
                flex: 0.2,
              },
            ]}
            disableColumnSelector
            disableRowSelectionOnClick
            hideFooter
            pagination={false}
            rows={[...loadOrganisationChecklists.data]
              .sort((a, b) => {
                return (
                  +getChecklistStatus(b.id).active -
                  +getChecklistStatus(a.id).active
                );
              })
              .map(cl => ({
                id: cl.id,
                name: cl.name,
                description: cl.description,
                submissions: cl.submissions || [],
              }))}
            getRowHeight={r => 'auto'}
            pinnedColumns={{
              left: [],
              right: ['actions'],
            }}
          />
        </Box>
        <SubmissionsDialog
          dialogContent={dialogContent}
          onClose={() => setDialogContent(null)}
        />
        <DueDateDialog
          onClose={() => setDueDateDialogId(null)}
          checklist={loadOrganisationChecklists.data.find(
            cl => cl.id === dueDateDialogId,
          )}
          setting={getChecklistStatus(dueDateDialogId)}
          onSave={v => {
            update(dueDateDialogId, { due_date: v });
          }}
        />
      </Stack>
    </Box>
  );
}
