/**
 *
 * OrganisationSelectButton
 *
 */
import React from 'react';
import { Button, Typography } from '@mui/material';
import { RiArrowDropDownLine } from 'react-icons/ri';
interface Props {
  text: string;
  onClick: Function;
}

export function OrganisationSelectButton(props: Props) {
  return (
    <Button
      onClick={ev => {
        props.onClick();
      }}
      variant="text"
      color="inherit"
      sx={{ textDecoration: 'none', px: 0 }}
      endIcon={<RiArrowDropDownLine size={25} />}
    >
      <Typography
        style={{
          textTransform: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.text}
      </Typography>
    </Button>
  );
}
