import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { RiskHelpModal } from '../RiskHelpModal';

interface Props {
  subheader: 'archived' | 'active';
}

export function RiskSubheader(props: Props) {
  const { subheader } = props;

  const theme = useTheme();
  const mdViewUp = useMediaQuery(theme.breakpoints.up('md'));

  const content = () => {
    switch (subheader) {
      case 'archived':
        return mdViewUp
          ? [
              'Archived risks',
              'Risk category',
              'Owner when archived',
              'Archived on',
            ]
          : ['Risk', 'Category', '', 'Date'];
      case 'active':
        return ['Risk', 'Owner', 'Updated', 'Mitigated'];
    }
  };

  return (
    <>
      <Box>
        <Stack
          direction={'row'}
          spacing={0}
          justifyContent={'space-between'}
          sx={{
            marginLeft: '55px',
          }}
        >
          <Grid container spacing={0} alignItems={'center'}>
            <Grid item xs={mdViewUp ? 6 : 7}>
              <Typography fontWeight={'bold'}>{content()[0]}</Typography>
            </Grid>

            <Grid item xs={mdViewUp ? 3 : 5}>
              <Typography fontWeight={'bold'}>{content()[1]}</Typography>
            </Grid>
            {mdViewUp && (
              <Grid item xs={3}>
                <Typography fontWeight={'bold'}>{content()[2]}</Typography>
              </Grid>
            )}
          </Grid>
          {content()[3] === 'Mitigated' ? (
            <Stack
              direction={'row'}
              spacing={1.5}
              alignItems={'center'}
              sx={{ width: 150 }}
            >
              <Box sx={{ width: 90 }}>
                <Typography fontWeight={'bold'}>{content()[3]}</Typography>
              </Box>
              <Box sx={{ width: 60 }}>
                <RiskHelpModal
                  iconTitle="Find more about mitigated risk score"
                  name="mitigated_risk_score"
                />
              </Box>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent={'center'}
              sx={{ width: '150px' }}
            >
              <Typography fontWeight={'bold'}>{content()[3]}</Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
}
