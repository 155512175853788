/**
 *
 * DocumentForm
 *
 */
import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { RiCheckFill, RiCloseLine } from 'react-icons/ri';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { Field, Form, Formik } from 'formik';
import { TextField, Autocomplete, CheckboxWithLabel } from 'formik-mui';
import { IDocument } from 'types/types';
import { UploadApiCall } from 'types/ApiCall';
import * as Yup from 'yup';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';
import FileUpload from 'app/components/FileUpload';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';
import { RadioButton } from 'app/components/RadioButton';
import { AutoUpdateName } from 'app/components/AutoUpdateName';

interface Props {
  onClose: () => void;
  document: IDocument;
  onSave: (payload: Partial<IDocument>) => void;
  saveDocument: UploadApiCall;
  saving: boolean;
  categories?: { label: string; value: string }[];
  defaultCategory?: string;
}

type FormType = Partial<IDocument> & {
  add_review_date?: boolean;
  add_expiry_date?: boolean;
};

function createSchema(shouldValidate) {
  return Yup.object({
    file: Yup.mixed()
      .test(
        'is-object-or-string',
        'A document is required',
        value =>
          value &&
          (typeof value === 'string' ||
            (typeof value === 'object' && value !== null)),
      )
      .required('A document is required'),
    name: Yup.string().required('This field is required'),
    description: Yup.string(),
    categories: shouldValidate
      ? Yup.array().of(Yup.string()).min(1, 'At least one category')
      : Yup.array().of(Yup.string()).notRequired(),
  });
}

export function DocumentForm(props: Props) {
  const {
    document,
    onSave,
    saveDocument,
    onClose,
    saving,
    categories = [],
    defaultCategory = 'reference',
  } = props;

  const shouldValidateCategories = categories.length > 0;
  const schema = createSchema(shouldValidateCategories);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Formik<FormType>
        validationSchema={schema}
        initialValues={
          document
            ? {
                ...document,
                add_review_date: !!document.review_at,
                add_expiry_date: !!document.expires_at,
              }
            : ({
                id: undefined,
                name: '',
                description: '',
                categories: defaultCategory ? [defaultCategory] : [],
                file: undefined,
                expires_at: undefined,
                review_at: undefined,
                add_review_date: false,
                add_expiry_date: false,
              } as FormType)
        }
        enableReinitialize
        validateOnChange
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSave(values);
        }}
      >
        {formik => {
          const {
            touched,
            setFieldValue,
            isSubmitting,
            errors,
            dirty,
            values,
            isValid,
            submitCount,
          } = formik;

          console.log(errors);
          return (
            <Form>
              <DialogTitle>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box sx={{ mr: 'auto' }}>
                    {document ? 'Manage' : 'Create'} document
                  </Box>

                  <IconButton onClick={onClose} edge={'end'} size={'large'}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Typography variant={'h3'}>
                    Tell us about this Document
                  </Typography>
                  <RequiredFieldIndicator />
                  <Stack spacing={2}>
                    <Stack spacing={0.5}>
                      <FormControl>
                        <FileUpload
                          label={'Select document to upload *'}
                          name={'file'}
                          allowRemove={false}
                        />
                        {(!!submitCount || touched.file) && errors.file && (
                          <FormHelperText error>{errors.file}</FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                    <Box>
                      <Stack>
                        <Typography variant="h5">Document title *</Typography>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          label="Name"
                          fullWidth
                        />
                      </Stack>
                    </Box>
                    <Box>
                      <Stack>
                        <Typography variant="h5">Description</Typography>
                        <Field
                          component={TextField}
                          name="description"
                          label="Description"
                          fullWidth
                        />
                      </Stack>
                    </Box>
                    {(!!document &&
                      document?.parent_entity_type !== 'organisation') ||
                    categories.length === 0 ? null : (
                      <Box>
                        <Stack>
                          <Typography variant="h5">Category *</Typography>
                          <FormControl>
                            <Field
                              id="categories"
                              component={Autocomplete}
                              name="categories"
                              label="Categories"
                              fullWidth
                              multiple
                              getOptionLabel={option =>
                                categories.find(c => c.value === option)
                                  ?.label || option
                              }
                              renderInput={params => (
                                <MuiTextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  label="Categories"
                                  error={
                                    touched.categories && !!errors.categories
                                  }
                                />
                              )}
                              onChange={(e, val, reason) => {
                                setFieldValue('categories', val);
                              }}
                              options={categories.map(c => c.value)}
                            />{' '}
                            {(!!submitCount || touched.categories) &&
                              errors.categories && (
                                <FormHelperText error>
                                  {errors.categories}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Stack>
                      </Box>
                    )}
                    <Box pt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <RadioButton
                            onChange={e => {
                              setFieldValue(
                                'add_expiry_date',
                                e.target.checked,
                              );
                              if (document?.expires_at || document?.review_at) {
                                setFieldValue('expires_at', undefined);
                                setFieldValue('review_at', undefined);
                              }
                            }}
                            checked={!values.add_expiry_date}
                            label="This document is evergreen"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <RadioButton
                            onChange={e =>
                              setFieldValue(
                                'add_expiry_date',
                                !e.target.checked,
                              )
                            }
                            checked={values.add_expiry_date}
                            label="This document has an expiry date"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {values.add_expiry_date && (
                      <Box>
                        <Grid container spacing={2} alignItems={'center'}>
                          <Grid item xs={12}>
                            <Stack spacing={2}>
                              <Typography variant="h5">Expiry date</Typography>
                              <Stack direction={'row'} alignItems={'center'}>
                                <DateFieldWrapper
                                  label={'Expiry date'}
                                  name="expires_at"
                                  format={'DD/MM/YYYY'}
                                  sx={{ width: '100%' }}
                                />
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={1}>
                              <Box>
                                <Field
                                  type={'checkbox'}
                                  component={CheckboxWithLabel}
                                  name={'add_review_date'}
                                  onChange={e => {
                                    setFieldValue(
                                      'add_review_date',
                                      e.target.checked,
                                    );
                                    if (!e.target.checked) {
                                      setFieldValue('review_at', undefined);
                                    }
                                  }}
                                  Label={{
                                    label: `${
                                      values?.add_review_date ? 'Remove' : 'Add'
                                    } review date`,
                                  }}
                                />
                              </Box>
                              {values.add_review_date && (
                                <Stack spacing={2}>
                                  <Typography variant="h5">
                                    Review date
                                  </Typography>
                                  <DateFieldWrapper
                                    label={'Review date'}
                                    name="review_at"
                                    format={'DD/MM/YYYY'}
                                  />
                                </Stack>
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </DialogContent>
              <DialogActions>
                <AutoUpdateName />
                <Box width={'100%'}>
                  <CancelAndSaveButtons
                    disabled={saving || isSubmitting}
                    onCancel={onClose}
                    saveText="Save & Publish"
                  />
                </Box>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
}
