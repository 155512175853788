/**
 *
 * Meeting Card
 *
 */
import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  Box,
  BoxProps,
  ButtonBase,
  Card,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IAbridgedMeeting } from 'types/types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import LinkRenderer from './LinkRenderer';

interface Props extends BoxProps {
  meeting: IAbridgedMeeting;
  subtitleType?: 'committee' | 'organisation';
  links?: ReactNode;
}

export function MeetingCard(props: Props) {
  const { meeting, subtitleType = 'committee', links, sx } = props;

  const now = moment();
  const isPast = moment(meeting.date_end).diff(now) < 0;
  const meetingDaysToGo = moment(meeting.date_end).diff(now, 'days');
  const isOccurringNow = now.isBetween(meeting.date_start, meeting.date_end);
  const withinTheWeek =
    (meetingDaysToGo <= 7 && meetingDaysToGo >= 0) || isOccurringNow;
  const overAWeek = meetingDaysToGo <= 30 && meetingDaysToGo > 7;

  const chipColour = useMemo(() => {
    if (isPast) {
      return 'common.brown';
    } else if (withinTheWeek) {
      return 'error.main';
    } else if (overAWeek) {
      return 'warning.main';
    } else {
      return 'success.main';
    }
  }, [meeting.date_start, meeting.date_end]);

  const getLabel = useMemo(() => {
    if (isOccurringNow) {
      return 'Occurring now';
    }
    return isPast
      ? moment(meeting.date_end).fromNow().charAt(0).toUpperCase() +
          moment(meeting.date_end).fromNow().slice(1)
      : moment(meeting.date_start).fromNow().charAt(0).toUpperCase() +
          moment(meeting.date_start).fromNow().slice(1);
  }, [meeting.date_start, meeting.date_end, isOccurringNow]);

  const theme = useTheme();
  const cardHeight = 260;
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ButtonBase
      component={Link}
      sx={{ width: '100%' }}
      to={`/organisation/${meeting.organisation_id}/meetings/${meeting.id}`}
    >
      <Card
        variant="outlined"
        square
        sx={{
          p: 2,
          width: '100%',
          height: cardHeight,
          ...sx,
        }}
      >
        <Stack height={'100%'} justifyContent={'space-between'}>
          <Stack spacing={1}>
            <Box>
              <Chip
                sx={{ backgroundColor: chipColour, color: 'common.white' }}
                label={getLabel}
              />
            </Box>
            <Box>
              <Stack direction={{ xs: 'column', md: 'row' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  {moment(meeting.date_start).format('ddd DD MMM YYYY')}
                </Typography>
                {isMd && (
                  <Typography
                    sx={{ fontWeight: 600, '&::before': { content: '"•"' } }}
                  />
                )}
                <Typography sx={{ fontWeight: 600 }}>
                  {moment(meeting.date_start).format('HH:mm')} -{' '}
                  {moment(meeting.date_end).format('HH:mm')}
                </Typography>
              </Stack>
            </Box>
            <Typography variant="h3">{meeting.name}</Typography>
            <Typography color={'primary.main'} variant="body2">
              See meeting details
            </Typography>
            {links}
          </Stack>
          <Box alignContent={'end'}>
            <Stack spacing={1}>
              <Typography>Committee</Typography>
              <Typography fontWeight={'bold'}>
                {meeting.committee.name}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </ButtonBase>
  );
}
