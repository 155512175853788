// formik wrapped form with initial values and submit handler

import React, { useMemo, useRef } from 'react';
import { Field, Form as FormikForm, Formik, FormikProps } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import {
  AddCircle,
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  Business,
  DeleteOutline,
  PeopleOutline,
  PersonOutline,
} from '@mui/icons-material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { IActionDefinition, IActionSubtask, NestedObject } from 'types/types';
import key from 'weak-key';
import * as Yup from 'yup';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { RadioButton } from 'app/components/RadioButton';
import { TargetHelpText } from './TargetHelpText';
import { ActionDefinitionOptionsPayload } from '../slice/types';
import { OnClickFields } from './OnclickFields';
import { DateTimePickerWrapper } from 'app/components/DateTimePickerWrapper';

interface Props {
  item?: IActionDefinition;
  loading?: boolean;
  onSubmit: (values: NestedObject<FormInterface>) => void;
  options: ActionDefinitionOptionsPayload;
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  target: Yup.string(),
  context_ids: Yup.array().when('target', {
    is: 'members',
    then: Yup.array()
      .of(Yup.string()) // Assuming context_ids are strings; modify if they're a different type
      .min(1, 'Please select at least one member'),
    otherwise: Yup.array().notRequired(),
  }),
  // ... other fields if necessary
});

interface FormInterface extends IActionDefinition {
  has_subtasks: boolean;
  subtasks: IActionSubtask[];

  target: 'personal' | 'members' | 'organisation';
  member_ids?: number[];
}

export function Form(props: Props) {
  const { item, loading, onSubmit, options } = props;
  const {
    roles = [],
    members = [],
    date_rules = [],
    onclick_actions = [],
  } = options;

  const ref = useRef<FormikProps<FormInterface>>(null);

  useEffectOnChange(() => {
    ref.current?.setSubmitting(!!loading);
  }, loading);

  const target = useMemo(
    () =>
      item?.context_ids?.length
        ? 'members'
        : item?.context === 'organisation'
        ? 'organisation'
        : 'personal',
    [item],
  );
  const validateOnclickParameters = (values, baseKey = '') => {
    let errors = {};
    if (values.onclick) {
      const action = onclick_actions.find(a => a.id === values.onclick);
      (action?.parameters || []).forEach(p => {
        const key = `${baseKey}onclick_parameters.${p.field}`;
        if (
          !values.onclick_parameters[p.field] &&
          p.validation?.includes('required')
        ) {
          errors[key] = 'This field is required';
        }
        if (
          p.validation?.includes('url') &&
          !/^(http|https):\/\//.test(values.onclick_parameters[p.field])
        ) {
          errors[key] = 'Please enter a valid URL';
        }
      });
    }
    return errors;
  };
  // Custom validation function for dynamic fields
  const validateDynamicFields = values => {
    let errors = validateOnclickParameters(values);

    // Validate each subtask
    if (values.has_subtasks) {
      values.subtasks.forEach((subtask, index) => {
        const baseKey = `subtasks[${index}].`;
        if (!subtask.name) {
          errors[`${baseKey}.name`] = 'This field is required';
        }
        const subtaskErrors = validateOnclickParameters(subtask, baseKey);
        errors = { ...errors, ...subtaskErrors };
      });
    }

    return errors;
  };

  // Combining Yup validation and custom validation
  const combinedValidation = async values => {
    let errors = {};
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // Convert the errors into a more manageable structure
        err.inner.forEach(error => {
          if (error.path) errors[error.path] = error.message;
        });
      }
    }

    // Merge Yup errors with custom validation errors
    const dynamicErrors = validateDynamicFields(values);
    return { ...errors, ...dynamicErrors };
  };

  const initialValues: FormInterface = {
    id: (item && item.id) || undefined,
    name: (item && item.name) || '',
    description: (item && item.description) || '',
    context: (item && item.context) || '',
    onclick: (item && item.onclick) || '',
    onclick_parameters: (item && item.onclick_parameters) || {},
    due_date_offset: (item && item.due_date_offset) || '',
    due_date: (item && item.due_date) || '',
    priority: (item && item.priority) || 5,
    has_subtasks: (item && !!item.subtasks?.length) || false,
    subtasks: (item?.subtasks || []).map(s => ({
      id: s.id,
      name: s.name,
      onclick: s.onclick || '',
      onclick_parameters: s.onclick_parameters || {},
    })),
    enabled: item?.enabled || false,
    triggers: item?.triggers || [
      {
        type: 'action-definition-save',
      },
    ],
    target: item?.target || 'personal',
    context_ids: item?.context_ids || [],
  };

  return (
    <Formik<FormInterface>
      innerRef={ref}
      initialValues={initialValues}
      enableReinitialize
      validate={combinedValidation}
      onSubmit={(data, { setSubmitting }) => {
        console.log(data);
        onSubmit(data as NestedObject<FormInterface>);

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => {
        //        console.log(touched);
        return (
          <FormikForm>
            <Grid container rowSpacing={4}>
              <Grid item xs={12} md={12}>
                <Stack spacing={4}>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <RadioButton
                          label={'Personal action'}
                          icon={<PersonOutline />}
                          onChange={() => {
                            setFieldValue('context', 'member');
                            setFieldValue('target', 'personal');
                            setFieldValue('restriction_type', '');
                            setFieldValue('restriction_code', '');
                            setFieldValue('context_ids', []);
                          }}
                          checked={values.target === 'personal'}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <RadioButton
                          label={'Members action'}
                          icon={<PeopleOutline />}
                          onChange={() => {
                            setFieldValue('context', 'member');
                            setFieldValue('target', 'members');
                            if (values.target !== 'members')
                              setFieldValue('context_ids', []);
                          }}
                          checked={values.target === 'members'}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <RadioButton
                          label={'Organisation action'}
                          icon={<Business />}
                          onChange={() => {
                            setFieldValue('context', 'organisation');
                            setFieldValue('target', 'organisation');
                            setFieldValue('restriction_type', '');
                            setFieldValue('restriction_code', '');
                            setFieldValue('context_ids', []);
                          }}
                          checked={values.target === 'organisation'}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <TargetHelpText target={values.target} />
                  {values.target === 'organisation' && (
                    <Box>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel id="restrictions-label">
                            Restrict visibility?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="restrictions-label"
                            name="restrictions-radio-buttons-group"
                          >
                            <FormControlLabel
                              onClick={() => {
                                setFieldValue('restriction_type', '');
                                setFieldValue('restriction_code', '');
                              }}
                              checked={
                                values.restriction_type !== 'role' ||
                                values.restriction_code !== 'organisation-admin'
                              }
                              control={<Radio />}
                              label="No restriction"
                            />
                            <FormControlLabel
                              checked={
                                values.restriction_type === 'role' &&
                                values.restriction_code === 'organisation-admin'
                              }
                              onClick={() => {
                                setFieldValue('restriction_type', 'role');
                                setFieldValue(
                                  'restriction_code',
                                  'organisation-admin',
                                );
                              }}
                              control={<Radio />}
                              label="Organisation admin"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Box>
                  )}

                  {values.target === 'members' && (
                    <Stack>
                      <Paper
                        variant={'outlined'}
                        sx={{
                          p: 2,
                          borderColor: errors.context_ids ? 'error.main' : null,
                        }}
                      >
                        <Grid container>
                          {members.map(m => (
                            <Grid key={key(m)} item xs={12} sm={6} md={4}>
                              <Field
                                name={'context_ids'}
                                type={'checkbox'}
                                value={m.id}
                                component={CheckboxWithLabel}
                                Label={{
                                  label: `${m.first_name} ${m.last_name} (${m.role_name})`,
                                }}
                                onChange={event => {
                                  const value = Number(event.target.value);
                                  if (event.target.checked) {
                                    setFieldValue('context_ids', [
                                      ...values.context_ids,
                                      value,
                                    ]);
                                  } else {
                                    setFieldValue(
                                      'context_ids',
                                      values.context_ids.filter(
                                        id => id !== value,
                                      ),
                                    );
                                  }
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                      {errors.context_ids && (
                        <FormHelperText error>
                          {errors.context_ids}
                        </FormHelperText>
                      )}
                    </Stack>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Field
                  name={'name'}
                  label={'Action name'}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={'description'}
                  label={'Description'}
                  component={TextField}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePickerWrapper
                  name={'due_date'}
                  label={'Due date'}
                  format={'DD/MM/YYYY'}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="subtasks-label">Sub-tasks</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="subtasks-label"
                        name="subtasks-radio-buttons-group"
                      >
                        <FormControlLabel
                          onClick={() => {
                            setFieldValue('has_subtasks', false);
                            setFieldValue('subtasks', []);
                          }}
                          checked={!values.has_subtasks}
                          control={<Radio />}
                          label="This is a single-task action"
                        />
                        <FormControlLabel
                          checked={values.has_subtasks}
                          onClick={() => {
                            setFieldValue('has_subtasks', true);
                            setFieldValue('onclick', '');
                            setFieldValue('onclick_parameters', {});
                          }}
                          control={<Radio />}
                          label="This action has sub-tasks"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Box>
              </Grid>
              {!values.has_subtasks && (
                <>
                  <Grid item xs={12}>
                    <OnClickFields onclick_actions={onclick_actions} />
                  </Grid>
                </>
              )}
              {values.has_subtasks && (
                <Grid item xs={12}>
                  <Grid container>
                    {values.subtasks.map((subtask, index) => (
                      <React.Fragment key={`subtask-${index}`}>
                        <Grid item xs={12}>
                          <Paper variant={'outlined'} sx={{ p: 4, pt: 2 }}>
                            <Stack spacing={4}>
                              <Box>
                                <Stack
                                  direction="row"
                                  justifyContent={'flex-end'}
                                >
                                  <Button
                                    startIcon={<DeleteOutline />}
                                    variant="text"
                                    color="error"
                                    onClick={() =>
                                      setFieldValue('subtasks', [
                                        ...values.subtasks.slice(0, index),
                                        ...values.subtasks.slice(index + 1),
                                      ])
                                    }
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    variant="text"
                                    startIcon={<Up />}
                                    disabled={index === 0}
                                    onClick={() =>
                                      setFieldValue('subtasks', [
                                        ...values.subtasks.slice(0, index - 1),
                                        values.subtasks[index],
                                        values.subtasks[index - 1],
                                        ...values.subtasks.slice(index + 1),
                                      ])
                                    }
                                  >
                                    Move up
                                  </Button>
                                  <Button
                                    variant="text"
                                    startIcon={<Down />}
                                    disabled={
                                      index === values.subtasks.length - 1
                                    }
                                    onClick={() =>
                                      setFieldValue('subtasks', [
                                        ...values.subtasks.slice(0, index),
                                        values.subtasks[index + 1],
                                        values.subtasks[index],
                                        ...values.subtasks.slice(index + 2),
                                      ])
                                    }
                                  >
                                    Move down
                                  </Button>
                                </Stack>
                              </Box>
                              <Field
                                name={`subtasks[${index}].name`}
                                label={'Sub-task name'}
                                component={TextField}
                                fullWidth
                              />
                              <OnClickFields
                                fieldBase={`subtasks[${index}].`}
                                onclick_actions={onclick_actions}
                              />
                            </Stack>
                          </Paper>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant={'outlined'}
                        startIcon={<AddCircle />}
                        onClick={() =>
                          setFieldValue('subtasks', [
                            ...values.subtasks,
                            {
                              id: undefined,
                              name: 'New task',
                              onclick: '',
                              onclick_parameters: {},
                            },
                          ])
                        }
                      >
                        Add a sub-task
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  spacing={2}
                  justifyContent={'flex-end'}
                >
                  <Button
                    variant={'text'}
                    onClick={() => {
                      ref.current?.resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting || loading}>
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
