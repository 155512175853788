import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { meetingFormWidgetActions as actions } from '.';
import { selectMeetingFormWidgetSettings } from './selectors';

function* init(action) {
  try {
    const committees = yield call(apiRequest, {
      url: `committees`,
      method: 'get',
      data: {
        organisation_id: action.payload.organisation_id,
      },
    });

    yield put(actions.loadFormSuccess({ committees: committees.data }));
  } catch (e) {
    yield put(actions.loadFormSuccess(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadMeeting(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `meetings/${action.payload.id}`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadMeetingSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.loadMeetingFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveMeeting(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `meetings/${action.payload.id || ''}`,
      method: action.payload.id ? 'put' : 'post',
      data: action.payload,
    });

    yield put(actions.saveMeetingSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.saveMeetingFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* meetingFormWidgetSaga() {
  yield takeLatest(actions.loadFormRequest.type, init);
  yield takeLatest(actions.loadMeetingRequest.type, loadMeeting);
  yield takeLatest(actions.saveMeetingRequest.type, saveMeeting);
}
