import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { MeetingFormWidget } from 'app/widgets/MeetingFormWidget';
import { useDispatch, useSelector } from 'react-redux';
import { useMeetingsSlice } from './slice/hook';
import { selectLoadMeetings } from './slice/selectors';
import { useHasChanged } from 'utils/usePrevious';
import { IOrganisation } from 'types/types';
import { useEffectOnce } from 'utils/useEffectOnce';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { useCanCreateMeetings } from './useCanCreateMeetings';
import { MeetingsCarousel } from './MeetingsCarousel';
import { MeetingCard } from './MeetingCard';
import key from 'weak-key';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

interface Props {
  organisation: IOrganisation;
}

export function Upcoming(props: Props) {
  const [initialised, setInitialised] = useState(false);
  const { organisation } = props;
  const { actions } = useMeetingsSlice();
  const loadMeetings = useSelector(selectLoadMeetings);
  const { loadUser } = useSelector(selectAuthProvider);
  const dispatch = useDispatch();

  const loadMeetingsLoadingChanged = useHasChanged(loadMeetings.loading);
  const organisationChanged = useHasChanged(organisation);

  useEffect(() => {
    if (loadMeetingsLoadingChanged && !loadMeetings.loading) {
      if (loadMeetings.error) {
        // todo - redirect away from page (and log?)
        if (loadMeetings.error.code === 401) return;
      } else {
        setInitialised(true);
      }
    }
    if (organisationChanged && organisation.id) {
      load();
    }
  }, [
    loadMeetingsLoadingChanged,
    loadMeetings,
    organisationChanged,
    organisation.id,
  ]);

  useEffectOnce(() => {
    load();
  });

  const canCreateMeetings = useCanCreateMeetings(loadUser.data, organisation);

  const futureAndCurrentMeetings = useMemo(() => {
    return [
      ...loadMeetings.data
        .filter(m => m.historical_status === 'future')
        .slice(-1000),
      ...loadMeetings.data
        .filter(m => m.historical_status === 'current')
        .slice(-1000),
    ];
  }, [loadMeetings.data]);

  const recentMeetings = useMemo(() => {
    return loadMeetings.data
      .filter(meeting => {
        const numberOfMinutesAgo = moment(meeting.date_end).diff(
          moment(),
          'minutes',
        );
        return numberOfMinutesAgo < 0 && numberOfMinutesAgo > -(124 * 60 * 24);
      })
      .sort((a, b) => {
        const diffA = moment(a.date_end).diff(moment(), 'minutes');
        const diffB = moment(b.date_end).diff(moment(), 'minutes');
        return diffA - diffB;
      });
  }, [loadMeetings.data]);

  const load = useCallback(() => {
    dispatch(actions.loadMeetingsRequest({ organisation_id: organisation.id }));
  }, [organisation]);

  const showForm =
    loadMeetings.touched &&
    !loadMeetings.loading &&
    !futureAndCurrentMeetings.length;

  return (
    <>
      <Box
        sx={{
          py: 4,
        }}
      >
        <Stack spacing={6}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack>
              <Typography variant="h2">Upcoming & recent</Typography>
              {/* <Button variant="outlined" startIcon={<RiCalendarCheckLine />}>
              Get all new upcoming meetings in your diary instantly
            </Button> */}
              {!futureAndCurrentMeetings.length && (
                <Typography>
                  You have no upcoming meetings. Please use the form below if
                  you would like to schedule a new meeting
                </Typography>
              )}
            </Stack>
          </Stack>
          {!!futureAndCurrentMeetings.length && (
            <MeetingsCarousel meetings={futureAndCurrentMeetings} />
          )}{' '}
          {showForm && (
            <Stack spacing={2}>
              {canCreateMeetings && <MeetingFormWidget onChange={load} />}
            </Stack>
          )}
          {!!recentMeetings.length && (
            <Box>
              <Stack spacing={4}>
                <Typography variant="h2">Recently passed</Typography>
                <Typography variant="body2">
                  These meetings have passed, but minutes, transcriptions and
                  documents may still be of current use to you.
                </Typography>
                <Box>
                  <Grid container spacing={4}>
                    {recentMeetings.reverse().map(meeting => (
                      <Grid item key={key(meeting)} xs={12} md={6} lg={4}>
                        <MeetingCard
                          meeting={meeting}
                          // links={
                          //   <>
                          //     <Button
                          //       component={Link}
                          //       variant="text"
                          //       to={''}
                          //       sx={{ p: 0, justifyContent: 'start' }}
                          //       size="small"
                          //     >
                          //       Read meeting documents
                          //     </Button>
                          //     <Button
                          //       component={Link}
                          //       variant="text"
                          //       to={''}
                          //       sx={{ p: 0, justifyContent: 'start' }}
                          //       size="small"
                          //     >
                          //       Check meeting log (3 new updates)
                          //     </Button>
                          //   </>
                          // }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          )}
          <Button
            component={Link}
            variant="text"
            to={'../past'}
            sx={{ p: 0, justifyContent: 'start' }}
          >
            Browse all past meetings
          </Button>
        </Stack>
      </Box>
    </>
  );
}
