import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { countries } from '../SetupMfa/countries';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

export const TelephoneField = (props: Props) => {
  const { value, onChange, label = 'Enter phone number' } = props;

  const getNumber = (value?: string) => value || '';
  const [dialCode, setDialCode] = useState<any | ''>('');

  const [number, setNumber] = useState<string>(getNumber(value as string));

  const fullNumber = `${dialCode} ${number}`;

  function processPhoneNumber(inputNumber: string): [string | null, string] {
    const extractPlusAndNumbers = num => num.replace(/[^+\d]/g, '');
    const parsedInputNumber = extractPlusAndNumbers(inputNumber);

    if (parsedInputNumber.length > 7) {
      const dialCodePattern = /^(00|\+)(\d+)/;
      const dialCodeMatchArr = parsedInputNumber.match(dialCodePattern);
      if (dialCodeMatchArr) {
        const insertedNumber = '+' + dialCodeMatchArr[2];

        const reverse = num => num.split('').reverse().join('');
        const processExistingDialCodes = countries
          .map(c => reverse(extractPlusAndNumbers(c.phone)))
          .sort((a, b) => b.length - a.length);

        const reversedMatchingDialCode = processExistingDialCodes.find(c => {
          const sliceInsertedNumber = insertedNumber.slice(0, c.length);
          return reverse(sliceInsertedNumber) === c;
        });

        if (reversedMatchingDialCode) {
          const matchingDialCode = reverse(reversedMatchingDialCode);
          const processedNumber = insertedNumber.substring(
            matchingDialCode.length,
            15,
          );
          return [matchingDialCode, processedNumber];
        }
      }
      if (
        parsedInputNumber.startsWith('0') &&
        !parsedInputNumber.startsWith('00')
      ) {
        return ['+44', parsedInputNumber.substring(1)];
      }
    }
    return ['', parsedInputNumber];
  }

  useEffect(() => {
    if (value || fullNumber) {
      const [newDialCode, newNumber] = processPhoneNumber(number);

      if (newDialCode) {
        setDialCode(newDialCode);
      }

      setNumber(newNumber);
      onChange(fullNumber);
    }
  }, [fullNumber, value]);

  return (
    <>
      <Autocomplete
        sx={{ minWidth: 150 }}
        disablePortal
        clearOnBlur
        options={countries}
        autoHighlight
        autoSelect
        disableClearable={false}
        autoComplete
        selectOnFocus
        isOptionEqualToValue={(option, value) => option.phone === value}
        freeSolo={false}
        value={dialCode}
        onChange={(e, v, reason) => {
          if (v) {
            setDialCode(v.phone);
          } else {
            setDialCode(null)
          }
        }}
        filterOptions={(options, { inputValue }) => {
          const filteredOptions = options.filter(
            option =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.phone.includes(inputValue),
          );
          return filteredOptions;
        }}
        clearOnEscape
        renderOption={(props, option) => (
          <Box component={'li'} {...props}>
            {option.phone} ({option.label})
          </Box>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label="Dial code"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'tel',
            }}
          />
        )}
      />

      <TextField
        label={label}
        fullWidth
        value={number}
        onChange={e => setNumber(e.target.value)}
      />
    </>
  );
};
