import { takeLatest } from 'redux-saga/effects';
import { actionsSectionActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadOpenActions = createRequestSaga({
  actionType: actions.loadOpenActionsRequest.type,
  url: () => 'actions',
  data: d => d,
  successAction: actions.loadOpenActionsSuccess,
  failureAction: actions.loadOpenActionsFailed,
});

const updateAction = createRequestSaga({
  actionType: actions.updateActionRequest.type,
  url: ({ action_id }) => `actions/${action_id}/update-status`,
  data: d => d,
  method: 'put',
  successAction: actions.updateActionSuccess,
  failureAction: actions.updateActionFailed,
});

const loadArchivedActions = createRequestSaga({
  actionType: actions.loadArchivedActionsRequest.type,
  url: () => 'actions',
  data: d => ({ ...d, completed: true }),
  successAction: actions.loadArchivedActionsSuccess,
  failureAction: actions.loadArchivedActionsFailed,
});

const loadActionDefinitionOptions = createRequestSaga({
  actionType: actions.loadActionDefinitionOptionsRequest.type,
  url: () => 'member-action-definitions/options',
  data: d => d,
  successAction: actions.loadActionDefinitionOptionsSuccess,
  failureAction: actions.loadActionDefinitionOptionsFailed,
});

const loadAction = createRequestSaga({
  actionType: actions.loadActionRequest.type,
  url: ({ id }) => `actions/${id}`,
  data: d => ({ ...d }),
  successAction: actions.loadActionSuccess,
  failureAction: actions.loadActionFailed,
});

const loadActionDefinitions = createRequestSaga({
  actionType: actions.loadActionDefinitionsRequest.type,
  url: () => 'member-action-definitions',
  data: d => d,
  successAction: actions.loadActionDefinitionsSuccess,
  failureAction: actions.loadActionDefinitionsFailed,
});

const loadActionDefinition = createRequestSaga({
  actionType: actions.loadActionDefinitionRequest.type,
  url: ({ id }) => `member-action-definitions/${id}`,
  data: d => d,
  successAction: actions.loadActionDefinitionSuccess,
  failureAction: actions.loadActionDefinitionFailed,
});

const createActionDefinition = createRequestSaga({
  actionType: actions.createActionDefinitionRequest.type,
  url: () => 'member-action-definitions',
  data: ({ data, organisation_id }) => ({ ...data, organisation_id }),
  method: 'post',
  successAction: actions.createActionDefinitionSuccess,
  failureAction: actions.createActionDefinitionFailed,
});

const updateActionDefinition = createRequestSaga({
  actionType: actions.updateActionDefinitionRequest.type,
  url: ({ id }) => `member-action-definitions/${id}`,
  data: ({ data, organisation_id }) => ({ ...data, organisation_id }),
  method: 'put',
  successAction: actions.updateActionDefinitionSuccess,
  failureAction: actions.updateActionDefinitionFailed,
});

const deleteActionDefinition = createRequestSaga({
  actionType: actions.deleteActionDefinitionRequest.type,
  url: ({ id }) => `member-action-definitions/${id}`,
  method: 'delete',
  data: d => d,
  successAction: actions.deleteActionDefinitionSuccess,
  failureAction: actions.deleteActionDefinitionFailed,
});

export function* actionsSectionSaga() {
  yield takeLatest(actions.loadOpenActionsRequest.type, loadOpenActions);
  yield takeLatest(
    actions.loadArchivedActionsRequest.type,
    loadArchivedActions,
  );
  yield takeLatest(actions.loadActionRequest.type, loadAction);
  yield takeLatest(actions.updateActionRequest.type, updateAction);

  yield takeLatest(
    actions.loadActionDefinitionOptionsRequest.type,
    loadActionDefinitionOptions,
  );
  yield takeLatest(
    actions.loadActionDefinitionsRequest.type,
    loadActionDefinitions,
  );
  yield takeLatest(
    actions.loadActionDefinitionRequest.type,
    loadActionDefinition,
  );
  yield takeLatest(
    actions.createActionDefinitionRequest.type,
    createActionDefinition,
  );
  yield takeLatest(
    actions.updateActionDefinitionRequest.type,
    updateActionDefinition,
  );
  yield takeLatest(
    actions.deleteActionDefinitionRequest.type,
    deleteActionDefinition,
  );
}
