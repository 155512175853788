import { Box, Button, CardMedia, Stack, Typography } from '@mui/material';
import React from 'react';
import DashboardImage from './dashboard_img.png';
import { RiArrowLeftCircleFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
export function Help() {
  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h3">Help & guidance</Typography>
        <Typography component={'p'}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. At pariatur
          cupiditate et facilis ab explicabo itaque labore perspiciatis aperiam
          quis!
        </Typography>

        <Typography variant="h5">Lorem ipsum dolor sit amet.</Typography>
        <Typography component={'p'}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum
          autem alias vel. Reprehenderit fugiat nemo debitis consectetur, quos
          nam libero itaque nobis possimus excepturi quo accusamus eius a
          tempora ipsam quas laboriosam consequatur, aperiam eum ex assumenda
          cupiditate, velit iusto laborum. Officiis nihil debitis soluta qui
          veniam sit magnam voluptatum!
        </Typography>

        <CardMedia
          component={'img'}
          src={DashboardImage}
          alt="Dashboard image"
        />
        <Typography variant="h5">Lorem ipsum dolor sit amet.</Typography>
        <Typography component={'p'}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum
          autem alias vel. Reprehenderit fugiat nemo debitis consectetur, quos
          nam libero itaque nobis possimus excepturi quo accusamus eius a
          tempora ipsam quas laboriosam consequatur, aperiam eum ex assumenda
          cupiditate, velit iusto laborum. Officiis nihil debitis soluta qui
          veniam sit magnam voluptatum!
        </Typography>

        <Typography variant="h5">Lorem ipsum dolor sit amet.</Typography>
        <Typography component={'p'}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum
          autem alias vel. Reprehenderit fugiat nemo debitis consectetur, quos
          nam libero itaque nobis possimus excepturi quo accusamus eius a
          tempora ipsam quas laboriosam consequatur, aperiam eum ex assumenda
          cupiditate, velit iusto laborum. Officiis nihil debitis soluta qui
          veniam sit magnam voluptatum!
        </Typography>

        <Typography variant="h5">Lorem ipsum dolor sit amet.</Typography>
        <Typography component={'p'}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum
          autem alias vel. Reprehenderit fugiat nemo debitis consectetur, quos
          nam libero itaque nobis possimus excepturi quo accusamus eius a
          tempora ipsam quas laboriosam consequatur, aperiam eum ex assumenda
          cupiditate, velit iusto laborum. Officiis nihil debitis soluta qui
          veniam sit magnam voluptatum!
        </Typography>

        <Typography variant="h5">Lorem ipsum dolor sit amet.</Typography>
        <Typography component={'p'}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum
          autem alias vel. Reprehenderit fugiat nemo debitis consectetur, quos
          nam libero itaque nobis possimus excepturi quo accusamus eius a
          tempora ipsam quas laboriosam consequatur, aperiam eum ex assumenda
          cupiditate, velit iusto laborum. Officiis nihil debitis soluta qui
          veniam sit magnam voluptatum!
        </Typography>
        <Box>
          <Button
            startIcon={<RiArrowLeftCircleFill />}
            variant="text"
            component={Link}
            to={'../active'}
          >
            Back to actions list
          </Button>
        </Box>
      </Stack>
    </>
  );
}
