/**
 *
 * CommitteeMemberForm
 *
 */
import * as React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { RiCheckFill, RiCloseLine, RiGroupLine } from 'react-icons/ri';

import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { Select } from 'formik-mui';
import { ICommittee, ICommitteeMember, IMember, IRole } from 'types/types';
import key from 'weak-key';
import * as Yup from 'yup';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';

interface Props {
  committeeMember: ICommitteeMember | undefined;
  members: IMember[];
  roles: IRole[];
  committee: ICommittee;
  onClose: () => void;
  onSave: (payload: Partial<ICommitteeMember>) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  member_id: Yup.number().required('This field is required'),
  role_code: Yup.string().required('This field is required'),
});

export function CommitteeMemberForm(props: Props) {
  const {
    committeeMember,
    members,
    roles,
    committee,
    onSave,
    onClose,
    saving,
  } = props;

  return (
    <Formik
      validationSchema={schema}
      initialValues={
        committeeMember ||
        ({
          id: undefined,
          member_id: '',
          role_code: 'committee-user',
        } as Partial<ICommitteeMember>)
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave(values);
      }}
    >
      {formik => {
        const { setSubmitting, isSubmitting } = formik;

        return (
          <Form>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>
                  {committeeMember ? (
                    <>
                      Editing {committeeMember.first_name}{' '}
                      {committeeMember.last_name}{' '}
                    </>
                  ) : (
                    'Add committee member'
                  )}
                </Box>

                <IconButton onClick={onClose} edge={'end'} size={'large'}>
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Typography variant={'h3'}>Committee member details</Typography>
                <RequiredFieldIndicator />
                <Stack spacing={3} sx={{ mb: 3 }}>
                  <IconTitledContent
                    width={3}
                    icon={<RiGroupLine />}
                    title={'Member *'}
                  >
                    {committeeMember ? (
                      <Typography>
                        {committeeMember.first_name} {committeeMember.last_name}
                      </Typography>
                    ) : (
                      <Field
                        component={Select}
                        formControl={{ fullWidth: true }}
                        id="member_id"
                        name="member_id"
                        label="Member"
                        fullWidth
                      >
                        {members
                          .filter(
                            m =>
                              !committee.members.find(
                                cm => cm.member_id === m.id,
                              ),
                          )
                          .map(m => (
                            <MenuItem
                              key={key(m)}
                              value={m.id}
                            >{`${m.first_name} ${m.last_name}`}</MenuItem>
                          ))}
                      </Field>
                    )}
                  </IconTitledContent>
                  <IconTitledContent
                    width={3}
                    icon={<RiGroupLine />}
                    title={'Role *'}
                  >
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true }}
                      id="role_code"
                      name="role_code"
                      label="Role"
                      fullWidth
                    >
                      {roles.map(c => (
                        <MenuItem value={c.code}>{c.name}</MenuItem>
                      ))}
                    </Field>
                  </IconTitledContent>
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions>
              <CancelAndSaveButtons
                disabled={isSubmitting || saving}
                onCancel={onClose}
                saveIcon={<RiCheckFill />}
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
