/**
 *
 * ActionsSection
 *
 */
import * as React from 'react';
import { IOrganisation } from 'types/types';
import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { SectionMenu } from 'app/components/SectionMenu';

import { List } from 'app/pages/ActionsSection/List';
import { Completed } from './Completed';
import { Help } from './Help';
import { SectionHeader } from 'app/components/SectionHeader';
import { Item } from './Item';
import { ActionsManager } from './ActionsManager';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';

interface Props {
  organisation: IOrganisation;
}

export function ActionsSection(props: Props) {
  const { organisation } = props;
  const { hasPermission } = useHasPermission();
  const location = useLocation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Helmet title={'Actions'}>
        <meta name="description" content="Compliance actions page" />
      </Helmet>

      <SectionHeader
        title="Actions"
        sectionMenu={
          <>
            <SectionMenu
              title={isSm ? 'Open & in progress' : 'Open'}
              path={'./active'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/actions/active`
              }
            />

            <SectionMenu
              title="Completed"
              path={'./completed'}
              isActive={location.pathname ===
                `/organisation/${organisation.id}/actions/completed`}
            />

            {/* <SectionMenu
              title={isSm ? 'Help & guidance' : 'Help'}
              path={'./help-guidance'}
              isActive={location.pathname ===
                `/organisation/${organisation.id}/actions/help-guidance`}
            /> */}

            {hasPermission(
              'manage-own-action-definitions',
              'organisation',
              organisation.id,
            ) && (
              <SectionMenu
                title={isSm ? 'Actions Manager' : 'Manager'}
                path={'./manager'}
                isActive={location.pathname ===
                  `/organisation/${organisation.id}/actions/manager`}
              />
            )}
          </>
        }
      />
      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={4}>
            <Routes>
              <Route
                path={'active/:id'}
                element={<Item organisation={organisation} />}
              />
              <Route
                path={'active'}
                element={<List organisation={organisation} />}
              />
              {/* )} */}
              <Route
                path={'completed/*'}
                element={<Completed organisation={organisation} />}
              />
              <Route path={'help-guidance/*'} element={<Help />} />

              {hasPermission(
                'manage-own-action-definitions',
                'organisation',
                organisation.id,
              ) && (
                <Route
                  path={'manager/*'}
                  element={<ActionsManager organisation={organisation} />}
                />
              )}
              <Route path={'*'} element={<Navigate to={'active'} replace />} />
            </Routes>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
