// In your external component
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';

export const TargetHelpText = ({ target }) => {
  const [actionsHelpTitle, actionsHelpBody] = useMemo(() => {
    switch (target) {
      case 'members':
        return [
          'About Members Actions',
          'Members actions are visible to selected members of the organisation.',
        ];
      case 'organisation':
        return [
          'About Organisation Actions',
          'Organisation actions are visible to all members of the organisation. Any member can complete an organisation action. You can restrict who can see and complete actions by setting the visibility of the action.',
        ];
      default:
        return [
          'About Personal Actions',
          'Personal actions are only visible to you.',
        ];
    }
  }, [target]);

  return (
    <>
      <Typography variant="h5">{actionsHelpTitle}</Typography>
      <Typography variant="body2">{actionsHelpBody}</Typography>
    </>
  );
};
