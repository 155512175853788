import React, { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  icon: ReactNode;
  title: string;
  chip?: ReactNode;
  children: ReactNode;
}

export function DashboardCard(props: Props) {
  const { icon, title, chip, children } = props;

  return (
    <Box sx={{ border: 1, borderColor: 'common.grey', p: 3 }}>
      <Stack spacing={4}>
        <Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Box sx={{ width: 30, textAlign: 'center' }}>{icon}</Box>
                <Typography variant={'h5'}>{title}</Typography>
              </Stack>
            </Box>
            {chip}
          </Stack>
        </Box>
        <>{children}</>
      </Stack>
    </Box>
  );
}
