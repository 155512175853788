import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { RiAlertLine, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { ActionButton } from '../ActionButton';
import { noop } from 'utils/noop';

interface Props {
  icon?: ReactNode;
  title: string;
  onClose?: () => void;
  path?: string;
  handleClick?: () => void;
  isEnabled: boolean;
  color?: string;
}

export function Nag(props: Props) {
  const {
    title,
    icon = <RiAlertLine size={18} title="Alert" />,
    path,
    isEnabled,
    onClose,
    handleClick = noop,
    color = 'secondary.main',
  } = props;

  return (
    <>
      {isEnabled && (
        <ActionButton
          to={path}
          color={color}
          fullWidth
          onClick={handleClick}
          removeBorderRadius={true}
          sx={{
            position: 'relative',
            borderRadius: 0,
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box>{icon}</Box>
            <Typography>{title}</Typography>
          </Stack>

          {!!onClose && (
            <Box>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                }}
                aria-label="Close"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClose();
                }}
                size={'medium'}
              >
                <RiCloseLine />
              </IconButton>
            </Box>
          )}
        </ActionButton>
      )}
    </>
  );
}
