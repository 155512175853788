import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RiAppleFill, RiArrowDropDownLine } from 'react-icons/ri';
import { GoogleCalendarIcon } from '../assets/GoogleCalendarIcon';
import { OutlookIcon } from '../assets/OutlookIcon';
import { ICalendarLinks } from 'types/types';
import key from 'weak-key';
import { Office365Icon } from '../assets/Office365Icon';
import { YahooIcon } from '../assets/YahooIcon';

interface Props {
  calendar_links: ICalendarLinks;
  calendarView: 'list' | 'responsive' | 'dropdown';
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  buttonProps?: ButtonProps;
  iconProps?: string;
  buttonText?: ReactNode;
  dropdownProps?: MenuProps;
  filename?: string;
  menuProps?: MenuItemProps;
  linkProps?: ButtonProps;
}

export function AddToCalendar(props: Props) {
  const {
    buttonProps = {},
    buttonText = 'Add to calendar',
    dropdownProps = {},
    calendar_links,
    calendarView,
    filename = 'download',
    linkProps = {},
    breakpoint = 'md',
    menuProps = {},
  } = props;

  const [minWidth, setMinWidth] = useState<number>(250);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) setMinWidth(buttonRef.current.clientWidth);
  }, [buttonRef]);

  const handleCalendarButtonClick = url => {
    window.open(url, '_blank');
  };

  const siteIcon = site => {
    switch (site) {
      case 'google':
        return <GoogleCalendarIcon></GoogleCalendarIcon>;
      case 'webOffice':
        return <Office365Icon />; //<SiteIcon iconProps={Office365Logo}></SiteIcon>;
      case 'ics':
        return (
          <Stack direction={'row'} justifyContent={'center'}>
            <RiAppleFill fontSize={15} />
          </Stack>
        );
      case 'yahoo':
        return <YahooIcon />;
      case 'webOutlook':
        return <OutlookIcon />;
    }
  };

  const siteName = site => {
    switch (site) {
      case 'google':
        return 'Google Mail';
      case 'webOffice':
        return 'Office.com';
      case 'ics':
        return 'iCal';
      case 'yahoo':
        return 'Yahoo Mail';
      case 'webOutlook':
        return 'Outlook.com';
    }
  };

  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down(breakpoint));

  const displayList = () => {
    return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            px: 1,
          }}
        >
          {buttonText}:
        </Typography>
        {Object.keys(calendar_links).map(type => (
          <Button
            key={key({ calLink: calendar_links[type] })}
            variant="text"
            startIcon={siteIcon(type)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleCalendarButtonClick(calendar_links[type]);
            }}
            {...linkProps}
            sx={{
              px: 2,
              display: 'flex',
              fontSize: '11px',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            {siteName(type)}
          </Button>
        ))}
      </Box>
    );
  };

  const displayDropdown = () => {
    return (
      <Box>
        <Button
          ref={buttonRef}
          variant={'outlined'}
          endIcon={<RiArrowDropDownLine />}
          onClick={handleClick}
          sx={{ display: { xs: 'flex' } }}
          {...buttonProps}
        >
          {buttonText}
        </Button>

        {!!open && (
          <Menu
            onClose={handleClose}
            MenuListProps={{
              sx: { minWidth },
              'aria-labelledby': 'basic-button',
              ...dropdownProps,
            }}
            open={open}
            anchorEl={anchorEl}
          >
            {Object.keys(calendar_links).map(type => (
              <MenuItem
                key={key({ calLink: calendar_links[type] })}
                onMouseDown={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleCalendarButtonClick(calendar_links[type]);
                }}
                {...menuProps}
              >
                {siteName(type)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    );
  };

  const displayResponsive = () => {
    return isResponsive ? displayDropdown() : displayList();
  };

  const content = view => {
    switch (view) {
      case 'list':
        return displayList();
      case 'dropdown':
        return displayDropdown();
      case 'responsive':
        return displayResponsive();
    }
  };

  return <>{content(calendarView)}</>;
}
