import { Stack, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { IOrganisation } from 'types/types';
import { DashboardActionCategoryHeader } from 'app/components/DashboardActionCategoryHeader';
import { DashboardActionDetail } from 'app/components/DashboardActionDetail';
import { useDispatch, useSelector } from 'react-redux';
import { useActionSectionSlice } from './slice/hook';
import { selectActionsSection } from './slice/selectors';
import { useEffectOnce } from 'usehooks-ts';
import { theme } from 'styles/theme';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { useOnClickActions } from 'utils/useOnlickActions';

interface Props {
  organisation: IOrganisation;
}

export function List(props: Props) {
  const { organisation } = props;

  const { actions } = useActionSectionSlice();
  const dispatch = useDispatch();
  const { loadOpenActions, updateAction } = useSelector(selectActionsSection);
  const { doAction } = useOnClickActions({ organisation_id: organisation.id });
  const load = () =>
    dispatch(
      actions.loadOpenActionsRequest({
        organisation_id: organisation.id,
      }),
    );

  useEffectOnce(() => {
    load();
  });

  useHandleApiResponse(updateAction, 'Action updated', {
    onSuccess: () => load(),
  });

  const filterButton = {
    borderColor: 'grey.400',
    borderWidth: 1,
    px: 4,
    py: 2,
    borderRadius: 0.5,
    '&:hover': {
      borderWidth: 1,
    },
  };

  if (!loadOpenActions.data || (loadOpenActions.touched && !loadOpenActions.loading)) return <LoadingIndicator />;

  return (
    <>
      <Stack spacing={6}>
        {/* Export button */}
        {/* <Stack alignItems={'end'}>
          <Button startIcon={<RiShareForward2Line />} variant="outlined">
            Export, share & print
          </Button>
        </Stack> */}

        {/* Row of filters */}
        {/* <Box>
          <Stack direction={'row'} spacing={{ xs: 1, md: 4, lg: 6 }}>
            <Button variant="outlined" sx={filterButton} fullWidth>
              All tasks
            </Button>
            <Button variant="outlined" sx={filterButton} fullWidth>
              New tasks
            </Button>
            <Button variant="outlined" sx={filterButton} fullWidth>
              In progress
            </Button>
            <Button variant="outlined" sx={filterButton} fullWidth>
              Due today
            </Button>
            <Button variant="outlined" sx={filterButton} fullWidth>
              Priority
            </Button>
          </Stack>
        </Box> */}
        <DashboardActionCategoryHeader
          title="All open actions"
          numberOfActions={loadOpenActions.data.length}
        />
        {!!loadOpenActions.data.length ? (
          <>
            {loadOpenActions.data.map(action => (
              <Fragment key={action.id}>
                <DashboardActionDetail
                  onclickAction={doAction}
                  onUpdateAction={payload =>
                    dispatch(actions.updateActionRequest(payload))
                  }
                  sx={{
                    border: `1px solid ${theme.palette.common.grey}`,
                    px: 2,
                  }}
                  mode="list"
                  action={action}
                />
              </Fragment>
            ))}
          </>
        ) : (
          <>
            <Typography>You have no open actions</Typography>
          </>
        )}
      </Stack>
    </>
  );
}
