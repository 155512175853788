import { Box, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  text?: string;
}

export function RequiredFieldIndicator(props: Props) {
  const { text = '* Required fields' } = props;
  return (
    <Box mt={3} pb={1}>
      <Typography variant="body1" textAlign={'end'} sx={{ opacity: 0.6 }}>
        * Required fields
      </Typography>
    </Box>
  );
}
