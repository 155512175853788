import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  numberOfActions?: number;
}

export function DashboardActionCategoryHeader(props: Props) {
  const { title, numberOfActions } = props;

  return (
    <>
      <Box sx={{ p: 2, backgroundColor: 'grey.200' }}>
        <Stack
          component={'ul'}
          direction={'row'}
          spacing={4}
          alignItems={'center'}
          sx={{ color: 'grey.500' }}
        >
          <Typography variant="h5" color={'common.black'}>
            {title}
          </Typography>

          <Typography component={'li'} variant="body2" sx={{ pl: 1 }}>
            {numberOfActions ? numberOfActions : 'No'} actions
          </Typography>
          {/*
          <Typography
            component={'li'}
            variant="body2"
            sx={{ pl:1 }}
          >
            4 in progress
          </Typography>
          <Typography
            component={'li'}
            variant="body2"
            sx={{ pl:1 }}
          >
            4 new
          </Typography>
          */}
        </Stack>
      </Box>
    </>
  );
}
