/**
 *
 * Past Meetings
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { MeetingFormWidget } from 'app/widgets/MeetingFormWidget';
import { useDispatch, useSelector } from 'react-redux';
import { useMeetingsSlice } from './slice/hook';
import { selectLoadMeetings } from './slice/selectors';
import { useHasChanged } from 'utils/usePrevious';
import { IOrganisation } from 'types/types';
import { useEffectOnce } from 'utils/useEffectOnce';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { useCanCreateMeetings } from './useCanCreateMeetings';
import { Link } from 'react-router-dom';
import key from 'weak-key';
import moment from 'moment-timezone';

interface Props {
  organisation: IOrganisation;
}

export function Past(props: Props) {
  const [initialised, setInitialised] = useState(false);
  const { organisation } = props;
  const { actions } = useMeetingsSlice();
  const loadMeetings = useSelector(selectLoadMeetings);
  const { loadUser } = useSelector(selectAuthProvider);
  const dispatch = useDispatch();
  const { hasPermission } = useHasPermission();

  // const { open, close, isOpen, form, loadMeeting } = useMeetingFormWidget();

  const loadMeetingsLoadingChanged = useHasChanged(loadMeetings.loading);

  const organisationChanged = useHasChanged(organisation);

  useEffectOnce(() => {
    if (loadMeetingsLoadingChanged && !loadMeetings.loading) {
      if (loadMeetings.error) {
        // todo - redirect away from page (and log?)
        if (loadMeetings.error.code === 401) return;
      } else {
        setInitialised(true);
      }
    }
    if (organisationChanged && organisation.id) {
      load();
    }
  });

  useEffectOnce(() => {
    load();
  });

  const pastMeetings = loadMeetings.data.filter(meeting =>
    moment(meeting.date_start).isBefore(moment()),
  );

  const load = useCallback(() => {
    dispatch(actions.loadMeetingsRequest({ organisation_id: organisation.id }));
  }, [organisation]);

  return (
    <>
      <Box
        sx={{
          my: 4,
        }}
      >
        {/* {loadMeetings.data.length > 0 && canCreateMeetings && (
          <>
            <Box>
              <Button
                variant="outlined"
                startIcon={<RiAddCircleFill />}
                onClick={() => open({ organisation_id: organisation.id })}
              >
                Create
              </Button>
            </Box>
            <MeetingFormDialog
              open={isOpen}
              onClose={close}
              onChange={load}
            />
          </>
        )} */}
        <Stack spacing={6}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="h2">Past Meetings</Typography>
            {/* <Button variant="outlined" startIcon={<RiCalendarCheckLine />}>
              Subscribe to the board calendar in your own calendar
            </Button> */}
          </Stack>

          <Box>
            {pastMeetings.length ? (
              <>
                <Grid container spacing={4}>
                  {pastMeetings.map(meeting => (
                    <Grid key={key(meeting)} item xs={12} md={6} lg={4}>
                      <ButtonBase
                        component={Link}
                        to={`/organisation/${meeting.organisation_id}/meetings/${meeting.id}`}
                        sx={{ width: '100%' }}
                      >
                        <Card
                          variant="outlined"
                          sx={{ p: 2, minHeight: 180, width: '100%' }}
                          square
                        >
                          <Stack
                            spacing={2}
                            height={'100%'}
                            justifyContent={'space-between'}
                          >
                            <Typography fontWeight={'bold'}>
                              {moment(meeting.date_start).format(
                                'ddd DD MMM YYYY',
                              )}
                            </Typography>
                            <Typography fontWeight={'bold'}>
                              {moment(meeting.date_start).format('HH:mm')} -{' '}
                              {moment(meeting.date_end).format('HH:mm')}
                            </Typography>
                            <Typography fontWeight={'bold'} variant="h3">
                              {meeting.name}
                            </Typography>
                            <Typography color={'primary.main'} variant="body2">
                              See meeting details
                            </Typography>

                            {/* <Button
                        component={Link}
                        variant="text"
                        to={''}
                        sx={{ p: 0, justifyContent: 'start' }}
                        size="small"
                      >
                        Read meeting documents
                      </Button>
                      <Button
                        component={Link}
                        variant="text"
                        to={''}
                        sx={{ p: 0, justifyContent: 'start' }}
                        size="small"
                      >
                        Check meeting log (3 new updates)
                      </Button> */}
                          </Stack>
                        </Card>
                      </ButtonBase>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <Typography>You have no past meetings</Typography>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
}
