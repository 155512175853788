import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  title: ReactNode;
  cta?: ReactNode;
  sectionMenu?: ReactNode;
}

export function SectionHeader(props: Props) {
  const { title, sectionMenu, cta } = props;

  return (
    <>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #FFFFFF 0%, #EAF3FF 100%)',
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ py: 4 }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography
                variant="h2"
                color={'primary.main'}
                fontWeight={'700'}
              >
                {title}
              </Typography>
              <Box>{cta}</Box>
            </Stack>
          </Box>
          {sectionMenu && <Stack direction={'row'}>{sectionMenu}</Stack>}
        </Container>
      </Box>
    </>
  );
}
