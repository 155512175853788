/**
 *
 * Meetings
 *
 */
import * as React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { List } from './List';
import { Item } from './Item';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings } from './slice/selectors';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { SectionHeader } from 'app/components/SectionHeader';
import { Box, Button, Container } from '@mui/material';
import { SectionMenu } from 'app/components/SectionMenu';
import { Upcoming } from './Upcoming';
import { Past } from './Past';
import { Guide } from './Guide';
import { BoardCalendar } from './BoardCalendar';
import { RiAddCircleFill } from 'react-icons/ri';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from 'app/widgets/MeetingFormWidget';
import { useCanCreateMeetings } from './useCanCreateMeetings';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { useMeetingsSlice } from './slice/hook';

interface Props {
  organisation: IOrganisation;
}

export function Meetings(props: Props) {
  const { organisation } = props;
  const { reloadOrganisation } = useReloadCurrentOrganisation();
  const { loadMeetings, loadMeeting } = useSelector(selectMeetings);
  const { loadUser } = useSelector(selectAuthProvider);

  const { open, close, isOpen } = useMeetingFormWidget();
  const canCreateMeetings = useCanCreateMeetings(loadUser.data, organisation);
  useEffectOnChange(() => {
    reloadOrganisation();
  }, loadMeetings.data.length);
  const location = useLocation();
  const { actions } = useMeetingsSlice();
  const dispatch = useDispatch();
  const load = React.useCallback(() => {
    dispatch(actions.loadMeetingsRequest({ organisation_id: organisation.id }));
  }, [organisation]);

  const navigate = useNavigate();

  if (!organisation) return null;
  return (
    <>
      <Helmet title={'Meetings'}>
        <meta name="description" content="Meetings of the organisation" />
      </Helmet>
      <SectionHeader
        title={'My meetings'}
        sectionMenu={
          <>
            <SectionMenu
              title="Upcoming & recent"
              path="./upcoming"
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/meetings/upcoming`
              }
            />
            <SectionMenu
              title="Past meetings"
              path="./past"
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/meetings/past`
              }
            />
            {/* <SectionMenu
              title="Board calendar"
              path="./board-calendar"
              isActive={location.pathname === '/meetings/board-calendar'}
            />
            <SectionMenu
              title="Meetings guide"
              path="./guide"
              isActive={location.pathname === '/meetings/guide'}
            /> */}
          </>
        }
        cta={
          <>
            {!loadMeeting.data &&
              loadMeetings.data.length > 0 &&
              canCreateMeetings && (
                <>
                  <Button
                    color="secondary"
                    startIcon={<RiAddCircleFill />}
                    onClick={() => {
                      open({ organisation_id: organisation.id });
                    }}
                  >
                    Schedule new meeting
                  </Button>
                  <MeetingFormDialog
                    open={isOpen}
                    onClose={close}
                    onChange={load}
                  />
                </>
              )}
          </>
        }
      />

      <Container>
        <Routes>
          {/* <Route path={''} element={<List organisation={organisation} />} /> */}
          <Route
            path={':id/*'}
            element={<Item organisation={organisation} />}
          />
          <Route
            path={'upcoming'}
            element={<Upcoming organisation={organisation} />}
          />
          <Route path={'past'} element={<Past organisation={organisation} />} />
          <Route path={'guide'} element={<Guide />} />
          <Route path={'board-calendar'} element={<BoardCalendar />} />
          <Route path={'*'} element={<Navigate to={'upcoming'} />} />
        </Routes>
      </Container>
    </>
  );
}
