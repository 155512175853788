/**
 *
 * Checklists
 *
 */
import * as React from 'react';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { List } from './List';
import { Item } from './Item';

interface Props {
  organisation: IOrganisation;
}

export function Checklists(props: Props) {
  const { organisation } = props;
  if (!organisation) return null;

  return (
    <>
      <Helmet title={'Compliance checklists'}>
        <meta name="description" content="Checklists for the organisation" />
      </Helmet>

      <Routes>
        <Route path={''} element={<List organisation={organisation} />} />
        <Route path={':checklist_id'}>
          <Route path={''} element={<Item organisation={organisation} />} />
          <Route
            path={':submission_id'}
            element={<Item organisation={organisation} />}
          />
        </Route>
      </Routes>
    </>
  );
}
