import { Box, Button, MenuItem, Stack, Tab, Tabs } from '@mui/material';
import { useGetOrganisationMenuStructure } from 'app/pages/OrganisationPage/useGetOrganisationMenuStructure';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import key from 'weak-key';
interface Props {}

export function DrawerOrgMenu(props: Props) {
  const { tabs } = useGetOrganisationMenuStructure();
  const { currentOrganisationId } = useOrganisationSelector();

  return (
    <>
      <Stack spacing={0}>
        {tabs
          ?.filter(t => !t.hidden)
          .map((t, index) => (
            <Box key={index}>
              {t.links
                ? t.links.map(link => (
                    <MenuItem key={key(link)}>
                      <Button
                        variant="text"
                        fullWidth
                        color="inherit"
                        component={Link}
                        to={`/organisation/${currentOrganisationId}/${link.to}`}
                        sx={{
                          p: 0,
                          justifyContent: 'start',
                          textDecoration: 'none',
                          '&:hover': {
                            opacity: 1,
                          },
                        }}
                      >
                        {link.label}
                      </Button>
                    </MenuItem>
                  ))
                : !t.links && (
                    <MenuItem key={key(t)}>
                      <Button
                        variant="text"
                        color="inherit"
                        component={Link}
                        to={`/organisation/${currentOrganisationId}/${t.to}`}
                        sx={{
                          p: 0,
                          justifyContent: 'start',
                          textDecoration: 'none',
                          '&:hover': {
                            opacity: 1,
                          },
                        }}
                      >
                        {t.label}
                      </Button>
                    </MenuItem>
                  )}
            </Box>
          ))}
      </Stack>
    </>
  );
}
