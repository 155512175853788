/**
 *
 * MeetingForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import {
  RiBriefcase3Line,
  RiCalendarLine,
  RiEarthLine,
  RiHome5Line,
  RiPhoneLine,
  RiUserLine,
} from 'react-icons/ri';

import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { IUser } from 'types/types';
import honorifics from './honorifics.json';
import key from 'weak-key';
import { DateTimePickerWrapper } from 'app/components/DateTimePickerWrapper';
import { TelephoneField } from 'app/components/TelephoneField';

interface Props {
  user: IUser;
  onSave: (payload: Partial<IUser>) => void;
  saving: boolean;
}

export function PersonalDetailsForm(props: Props) {
  const { user, onSave, saving } = props;

  return (
    <Formik
      initialValues={
        {
          id: undefined,
          title: '',
          first_name: '',
          last_name: '',
          dob: '',
          email: '',
          ...user,
        } as Partial<IUser>
      }
      enableReinitialize
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave(values);
      }}
    >
      {formik => {
        const { setSubmitting, isSubmitting, dirty, values, setFieldValue } =
          formik;
        if (isSubmitting && !saving) setSubmitting(false);
        return (
          <Form>
            <Box>
              <Box mb={5}>
                <Stack>
                  <Typography>
                    Here you can edit your personal information. These details
                    will be visible by other board members throughout
                    Governance360.
                  </Typography>
                </Stack>
              </Box>

              <Stack spacing={3} sx={{ my: 3 }}>
                <IconTitledContent
                  width={2}
                  icon={<RiUserLine />}
                  title={'Name'}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={2} sx={{ mb: { xs: 2, md: 0 } }}>
                      <Field
                        component={Select}
                        id="title"
                        name="title"
                        label="Title"
                        formControl={{ fullWidth: true }}
                      >
                        {honorifics.englishHonorifics.map(t => (
                          <MenuItem key={key({ t })} value={t}>
                            {t}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ mb: { xs: 2, md: 0 } }}>
                      <Field
                        component={TextField}
                        id="first_name"
                        name="first_name"
                        label="First name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Field
                        component={TextField}
                        id="last_name"
                        name="last_name"
                        label="Last name"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </IconTitledContent>
                <IconTitledContent
                  width={2}
                  icon={<RiCalendarLine />}
                  title={'Date of Birth'}
                >
                  <DateTimePickerWrapper
                    name="dob"
                    label="D.O.B."
                    format={'DD/MM/YYYY'}
                    sx={{ width: '100%' }}
                  />
                </IconTitledContent>{' '}
                <IconTitledContent
                  width={2}
                  icon={<RiBriefcase3Line />}
                  title={'Occupation'}
                >
                  <Field
                    component={TextField}
                    id="occupation"
                    name="occupation"
                    placeholder={'Occupation'}
                    fullWidth
                  />
                </IconTitledContent>
                <IconTitledContent
                  width={2}
                  icon={<RiEarthLine />}
                  title={'Nationality'}
                >
                  <Field
                    component={TextField}
                    id="nationality"
                    name="nationality"
                    placeholder="Nationality"
                    fullWidth
                  />
                </IconTitledContent>
              </Stack>
            </Box>
            <Divider />
            <Box>
              <Box mb={5}>
                <Stack>
                  <Typography variant={'h3'}>Contact details</Typography>
                </Stack>
              </Box>

              <Stack spacing={3} sx={{ my: 3 }}>
                <IconTitledContent
                  width={2}
                  icon={<RiPhoneLine />}
                  title={'Telephone'}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Stack direction={'row'}>
                        <TelephoneField
                          id="telephone_mobile"
                          name="telephone_mobile"
                          autoComplete="tel"
                          value={values.telephone_mobile}
                          onChange={e => setFieldValue('telephone_mobile', e)}
                          label="Mobile"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Stack direction={'row'}>
                        <TelephoneField
                          id="telephone_home"
                          name="telephone_home"
                          autoComplete="tel"
                          value={values.telephone_home}
                          onChange={e => setFieldValue('telephone_home', e)}
                          label="Home"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={'row'}>
                        <TelephoneField
                          id="telephone_work"
                          name="telephone_work"
                          autoComplete="tel"
                          value={values.telephone_work}
                          onChange={e => setFieldValue('telephone_work', e)}
                          label="Work"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </IconTitledContent>

                <IconTitledContent
                  width={2}
                  icon={<RiHome5Line />}
                  title={'Address'}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="address1"
                        name="address1"
                        label="Address Line 1"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="address2"
                        name="address2"
                        label="Address Line 2"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="city"
                        name="city"
                        label="Town/city"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="county"
                        name="county"
                        label="County"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ mb: { xs: 2, sm: 0 } }}>
                      <Field
                        component={TextField}
                        id="country"
                        name="country"
                        label="Country"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        id="postcode"
                        name="postcode"
                        label="Postcode"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </IconTitledContent>
              </Stack>
            </Box>

            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Button
                variant={'outlined'}
                type={'reset'}
                disabled={!dirty || saving || isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type={'submit'}
                disabled={!dirty || saving || isSubmitting}
              >
                Save changes
              </Button>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
