/**
 *
 * MemberForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  RiCheckFill,
  RiCloseLine,
  RiDeleteBackLine,
  RiFilePaper2Line,
  RiGroupLine,
  RiMailLine,
  RiUserLine,
} from 'react-icons/ri';

import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { ICommittee, IMember, IRole } from 'types/types';
import * as Yup from 'yup';
import key from 'weak-key';
import { FormikMultiSelectCheckboxes } from 'app/components/FormikMultiSelectCheckboxes';
import { Circle } from '@mui/icons-material';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';

interface Props {
  onClose: () => void;
  member: IMember | undefined;
  roles: IRole[];
  committees: ICommittee[];
  onSave: (payload: Partial<IMember>) => void;
  onDelete?: (payload: IMember) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .when('id', {
      is: v => !v,
      then: Yup.string()
        .email('Must be a valid email')
        .required('This field is required'),
    }),
  committees: Yup.array()
    .of(Yup.number())
    .when('id', {
      is: v => !v,
      then: Yup.array()
        .of(Yup.number())
        .required('At least one selection is required'),
    }),
  first_name: Yup.string().when('id', {
    is: v => !v,
    then: Yup.string().required('This field is required'),
  }),
  last_name: Yup.string().when('id', {
    is: v => !v,
    then: Yup.string().required('This field is required'),
  }),
  job_title: Yup.string().required('This field is required'),
  role_code: Yup.string().required('This field is required'),
});

export function MemberForm(props: Props) {
  const { member, roles, committees, onClose, onSave, onDelete, saving } =
    props;

  return (
    <Formik
      validationSchema={schema}
      initialValues={
        (member || {
          id: undefined,
          email: '',
          first_name: '',
          last_name: '',
          job_title: '',
          status: 'active',
          role_code: 'organisation-user',
          committees: committees.filter(c => c.is_default).map(c => c.id),
        }) as Partial<IMember> & {
          email?: string;
          committees?: Array<number | string>;
        }
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave(values);
      }}
    >
      {formik => {
        const { setSubmitting, values, isSubmitting } = formik;
        // @ts-ignore
        return (
          <Form>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>
                  {member ? 'Edit member' : 'Invite member'}
                </Box>

                <IconButton onClick={onClose} edge={'end'} size={'large'}>
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Typography variant={'h3'}>Member details</Typography>
                <RequiredFieldIndicator />
                <Stack spacing={3} sx={{ mb: 3 }}>
                  <IconTitledContent
                    visible={!member}
                    width={3}
                    icon={<RiUserLine />}
                    title={'Name *'}
                  >
                    {member ? (
                      <Typography>
                        {member.first_name} {member.last_name}{' '}
                      </Typography>
                    ) : (
                      <Grid container>
                        <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
                          <Field
                            component={TextField}
                            id="first_name"
                            type="text"
                            name="first_name"
                            label="First name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            id="last_name"
                            type="text"
                            name="last_name"
                            label="Last name"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    )}
                  </IconTitledContent>
                  <IconTitledContent
                    width={3}
                    icon={<RiMailLine />}
                    title={member ? 'Member name *' : 'Email *'}
                  >
                    {member ? (
                      <Typography>
                        {member.first_name} {member.last_name}{' '}
                      </Typography>
                    ) : (
                      <Field
                        component={TextField}
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        fullWidth
                      />
                    )}
                  </IconTitledContent>

                  <IconTitledContent
                    width={3}
                    icon={<RiGroupLine />}
                    title={'Job title *'}
                  >
                    <Field
                      component={TextField}
                      id="job_title"
                      name="job_title"
                      placeholder="Job title"
                      fullWidth
                    />
                  </IconTitledContent>

                  <IconTitledContent
                    width={3}
                    icon={<RiGroupLine />}
                    title={'Member role *'}
                  >
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true }}
                      id="role_code"
                      name="role_code"
                      placeholder="Member role"
                      fullWidth
                    >
                      {roles.map(c => (
                        <MenuItem key={key(c)} value={c.code}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </IconTitledContent>
                  <IconTitledContent
                    width={3}
                    icon={<RiGroupLine />}
                    title={'Status *'}
                  >
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true }}
                      id="status"
                      name="status"
                      placeholder="Status"
                      fullWidth
                    >
                      {[
                        {
                          value: 'active',
                          label: 'Active',
                          color: 'success',
                        },
                        {
                          value: 'suspended',
                          label: 'Suspended',
                          color: 'error',
                        },
                      ].map(s => (
                        <MenuItem key={key(s)} value={s.value}>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={1}
                          >
                            <Circle color={s.color as 'error' | 'success'} />
                            <Box>{s.label}</Box>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Field>
                  </IconTitledContent>
                  {!!committees.length && (
                    <IconTitledContent
                      visible={!member}
                      width={3}
                      icon={<RiFilePaper2Line />}
                      title={'Committees'}
                    >
                      <Field
                        component={FormikMultiSelectCheckboxes}
                        multiple
                        formControl={{ fullWidth: true }}
                        id="committees"
                        name="committees"
                        placeholder="Committees"
                        fullWidth
                        options={committees.map(c => ({ ...c, label: c.name }))}
                      />
                    </IconTitledContent>
                  )}
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions sx={{}}>
              {!!(member && onDelete) && (
                <Button
                  variant={'text'}
                  color={'error'}
                  startIcon={<RiDeleteBackLine />}
                  disabled={saving || isSubmitting}
                  sx={{ mr: 'auto' }}
                  onClick={() => onDelete(member)}
                >
                  Delete member
                </Button>
              )}
              <CancelAndSaveButtons
                disabled={isSubmitting || saving}
                saveText="Send"
                saveIcon={<RiCheckFill />}
                onCancel={onClose}
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
