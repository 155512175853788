/**
 *
 * AppNavBar
 *
 */
import * as React from 'react';
import { ReactNode, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Logo } from '../Logo';
import {
  RiAccountCircleFill,
  RiArrowDropDownLine,
  RiArrowDropRightLine,
  RiMenuFill,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { OrganisationSelectButton } from '../../providers/OrganisationSelector';
import { noop } from 'utils/noop';
import { useSelector } from 'react-redux';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { DrawerOrgMenu } from '../DrawerOrgMenu';

interface Props {
  menuComponent?: ReactNode;
  currentPath?: string;
  logout?: () => void;
}

const MenuButton = ({ sx = null, ...rProps }) => (
  <Button
    fullWidth
    {...rProps}
    variant="text"
    sx={{
      fontWeight: 500,
      ...(sx ? sx : {}),
    }}
  />
);

export function AppNavBar(props: Props) {
  const { logout = noop, menuComponent, currentPath } = props;
  const { loadUser } = useSelector(selectAuthProvider);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [expandProfile, setExpandProfile] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      event.stopPropagation();
      setDrawerOpen(open);
    };

  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <>
      <Button
        href="#main-content"
        fullWidth
        variant="outlined"
        component={'a'}
        sx={{
          position: 'absolute',
          '&:focus': {
            position: 'relative',
            borderRadius: 0,
            border: 0,
          },
        }}
      >
        Skip to content
      </Button>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'primary.main' }}
        position="sticky"
      >
        <Container>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            py={2}
          >
            <Box>
              <Box
                sx={{
                  display: 'block',
                  width: {
                    xs: 100,
                    md: 140,
                    xl: 170,
                  },
                  '& > svg': {
                    width: '100%',
                  },
                }}
                component={Link}
                to={'/'}
              >
                <Logo title="Go to home page" />
              </Box>
            </Box>
            {isLg ? (
              <Box>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mr: 'auto',
                    ml: 4,
                  }}
                >
                  <OrganisationSelectButton />
                </Box>
              </Box>
            ) : (
              <IconButton onClick={toggleDrawer(true)}>
                <RiMenuFill color={theme.palette.common.white} size={25} />
              </IconButton>
            )}
          </Stack>
          {isLg && (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box>{menuComponent}</Box>

              <Box>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={3}
                >
                  {/* <RiNotification3Fill size={25} /> */}

                  <Box>
                    <MenuButton
                      id="profile-menu-button"
                      onClick={handleClick}
                      color="inherit"
                      startIcon={<RiAccountCircleFill size={25} />}
                      endIcon={<RiArrowDropDownLine size={25} />}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      sx={{
                        color: 'common.white',
                        px: 0,
                        borderRadius: 0,
                      }}
                    >
                      My Profile
                    </MenuButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              left: 'calc(75% - 5px)',
                              width: 10,
                              height: 10,
                              bgColor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem component={Link} to={'/profile'}>
                        Personal details
                      </MenuItem>
                      <MenuItem component={Link} to={'/profile/security'}>
                        Security & passwords
                      </MenuItem>
                      {!!loadUser.data && !!loadUser.data.thinkific_enabled && (
                        <MenuItem
                          component={Link}
                          to={'/profile/academy-record'}
                        >
                          Academy record
                        </MenuItem>
                      )}
                      <MenuItem component={Link} to={'/profile/declarations'}>
                        Declarations of Interest
                      </MenuItem>
                      <MenuItem
                        component={'a'}
                        href={'//knowledge.governance360.com'}
                        target={'_blank'}
                      >
                        Knowledge base
                      </MenuItem>
                      <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          )}
        </Container>
      </AppBar>
      {!isLg && (
        <SwipeableDrawer
          anchor={'right'}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          PaperProps={{
            sx: {
              backgroundColor: theme => theme.palette.grey[200],
            },
          }}
        >
          <Box
            sx={{ maxWidth: 400, width: '100vw' }}
            role="presentation"
            color={'grey.700'}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <MenuList>
              <MenuItem>
                <OrganisationSelectButton />
              </MenuItem>

              <Divider sx={{ my: 1 }} />

              <DrawerOrgMenu />

              <Divider sx={{ my: 1 }} />
              <MenuButton
                color="inherit"
                startIcon={<RiAccountCircleFill size={25} />}
                endIcon={
                  expandProfile ? (
                    <RiArrowDropDownLine size={25} />
                  ) : (
                    <RiArrowDropRightLine size={25} />
                  )
                }
                onClick={event => {
                  event.stopPropagation();
                  setExpandProfile(!expandProfile);
                }}
                sx={{
                  justifyContent: 'start',
                }}
              >
                My Profile
              </MenuButton>
              {expandProfile && (
                <Box>
                  <MenuItem component={Link} to={'/profile'}>
                    Personal details
                  </MenuItem>
                  <MenuItem component={Link} to={'/profile/security'}>
                    Security & passwords
                  </MenuItem>
                  {!!loadUser.data && !!loadUser.data.thinkific_enabled && (
                    <MenuItem component={Link} to={'/profile/academy-record'}>
                      Academy record
                    </MenuItem>
                  )}
                  <MenuItem component={Link} to={'/profile/declarations'}>
                    Declarations of Interest
                  </MenuItem>
                  <MenuItem
                    component={'a'}
                    href={'//knowledge.governance360.com'}
                    target={'_blank'}
                  >
                    Knowledge base
                  </MenuItem>
                  <Divider />
                </Box>
              )}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </MenuList>
          </Box>
        </SwipeableDrawer>
      )}
    </>
  );
}
