import { call, cancelled, put, take, takeLatest } from 'redux-saga/effects';
import { meetingsActions as actions } from '.';
import { createRequestSaga, createUploadRequestSaga } from 'utils/saga';
import { createUploadFileChannel } from '../../../../utils/saga/createFileUploadChannel';

const loadMeetings = createRequestSaga({
  actionType: actions.loadMeetingsRequest.type,
  url: `meetings`,
  data: p => ({ ...p, 'page[size]': 500 }),
  successAction: actions.loadMeetingsSuccess,
  failureAction: actions.loadMeetingsFailed,
});

const loadMeeting = createRequestSaga({
  actionType: actions.loadMeetingRequest.type,
  url: p => `meetings/${p.id}`,
  successAction: actions.loadMeetingSuccess,
  failureAction: actions.loadMeetingFailed,
});

const shareMeeting = createRequestSaga({
  actionType: actions.shareMeetingRequest.type,
  url: p => `meetings/${p.id}/share`,
  method: 'post',
  successAction: actions.shareMeetingSuccess,
  failureAction: actions.shareMeetingFailed,
});

const deleteMeeting = createRequestSaga({
  actionType: actions.deleteMeetingRequest.type,
  url: p => `meetings/${p.id}`,
  method: 'delete',
  successAction: actions.deleteMeetingSuccess,
  failureAction: actions.deleteMeetingFailed,
});

const saveDocument = createUploadRequestSaga({
  actionType: actions.saveDocumentRequest.type,
  getURL: ({ meetingId, category }) =>
    `meetings/${meetingId}/document/${category}`,
  getMethod: payload => 'post',
  progressAction: actions.saveDocumentProgress,
  successAction: actions.saveDocumentSuccess,
  failureAction: actions.saveDocumentFailed,
});

const deleteDocument = createRequestSaga({
  actionType: actions.deleteDocumentRequest.type,
  url: ({ meetingId, documentId }) =>
    `meetings/${meetingId}/document/${documentId}`,
  method: 'delete',
  successAction: actions.deleteDocumentSuccess,
  failureAction: actions.deleteDocumentFailed,
});

const reorderDocuments = createRequestSaga({
  actionType: actions.reorderDocumentsRequest.type,
  url: ({ meetingId }) => `meetings/${meetingId}/reorder-documents`,
  method: 'post',
  data: ({ oldIndex, newIndex }) => ({ oldIndex, newIndex }),
  successAction: actions.reorderDocumentsSuccess,
  failureAction: actions.reorderDocumentsFailed,
});

export function* meetingsSaga() {
  yield takeLatest(actions.loadMeetingsRequest.type, loadMeetings);
  yield takeLatest(actions.loadMeetingRequest.type, loadMeeting);
  yield takeLatest(actions.shareMeetingRequest.type, shareMeeting);
  yield takeLatest(actions.deleteMeetingRequest.type, deleteMeeting);

  yield takeLatest(actions.saveDocumentRequest.type, saveDocument);
  yield takeLatest(actions.deleteDocumentRequest.type, deleteDocument);
  yield takeLatest(actions.reorderDocumentsRequest.type, reorderDocuments);
}
