/**
 *
 * ActionsSection
 *
 */
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { IActionDefinition, IOrganisation } from 'types/types';
import { useActionSectionSlice } from '../slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectActionsSection } from '../slice/selectors';
import { useEffectOnce } from 'usehooks-ts';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ActionDefinitionListItem } from './ActionDefinitionListItem';
import { RiAddCircleFill, RiPencilFill } from 'react-icons/ri';
import key from 'weak-key';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import useHandleApiResponse from 'utils/useHandleApiResponse';

interface Props {
  organisation: IOrganisation;
}

export function List(props: Props) {
  const { organisation } = props;

  const confirm = useConfirm();

  const { actions } = useActionSectionSlice();
  const dispatch = useDispatch();
  const {
    loadActionDefinitions,
    updateActionDefinition,
    deleteActionDefinition,
  } = useSelector(selectActionsSection);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      actions.loadActionDefinitionsRequest({
        organisation_id: organisation.id,
      }),
    );
  }, [organisation.id]);

  useHandleApiResponse(loadActionDefinitions, null, {
    errorMessage: 'Failed to load actions',
  });
  useHandleApiResponse(updateActionDefinition, 'Action updated', {
    onSuccess: () =>
      dispatch(
        actions.loadActionDefinitionsRequest({
          organisation_id: organisation.id,
        }),
      ),
  });
  useHandleApiResponse(deleteActionDefinition, 'Action deleted', {
    onSuccess: () =>
      dispatch(
        actions.loadActionDefinitionsRequest({
          organisation_id: organisation.id,
        }),
      ),
  });

  const { active, inactive } = useMemo(() => {
    return loadActionDefinitions.data.reduce(
      (
        acc: {
          active: any[];
          inactive: any[];
        },
        ad,
      ) => {
        if (ad.enabled) {
          acc.active.push(ad);
        } else {
          acc.inactive.push(ad);
        }
        return acc;
      },
      { active: [], inactive: [] },
    );
  }, [loadActionDefinitions.data]);

  const deleteItem = (ad: IActionDefinition) => {
    confirm({ description: 'Are you sure you want to delete this action?' })
      .then(() =>
        dispatch(
          actions.deleteActionDefinitionRequest({
            id: ad.id,
            organisation_id: organisation.id,
          }),
        ),
      )
      .catch(() => null);
  };

  return (
    <Stack spacing={6}>
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          startIcon={<RiAddCircleFill />}
          variant="outlined"
          onClick={() => navigate(`./new`)}
        >
          Create an action
        </Button>
        {/* <Button
          startIcon={<RiPencilFill />}
          variant="outlined"
          onClick={() => navigate(`./edit`)}
        >
          Edit & Manage actions
        </Button> */}
      </Stack>
      {!loadActionDefinitions.loading &&
        loadActionDefinitions.touched &&
        !active.length &&
        !inactive.length && (
          <Typography>
            You have no actions. Click the button above to create one.
          </Typography>
        )}

      <Stack spacing={4}>
        <Box
          sx={{
            backgroundColor: theme => theme.palette.common.purple.light,
            p: 3,
          }}
        >
          <Typography variant={'h5'}>Live actions</Typography>
        </Box>
        {active.length > 0 ? (
          <>
            {active.map((ad: any) => {
              return (
                <ActionDefinitionListItem
                  key={key(ad)}
                  ad={ad}
                  isActive={true}
                  onDelete={() => deleteItem(ad)}
                  onEdit={() => navigate(`./${ad.id}`)}
                  onPause={() =>
                    dispatch(
                      actions.updateActionDefinitionRequest({
                        id: ad.id,
                        organisation_id: organisation.id,
                        data: { ...ad, enabled: false },
                      }),
                    )
                  }
                />
              );
            })}
          </>
        ) : (
          <Box sx={{pl:3}}>
            <Typography>You have no live actions</Typography>
          </Box>
        )}
        </Stack>
        <Stack spacing={4}>
          <Box
            sx={{
              backgroundColor: theme => theme.palette.common.purple.light,
              p: 3,
            }}
          >
            <Typography variant={'h5'}>Draft actions</Typography>
          </Box>
          {inactive.length > 0 ? (
            <>
              {inactive.map((ad: any) => {
                return (
                  <ActionDefinitionListItem
                    key={key(ad)}
                    ad={ad}
                    isActive={false}
                    onDelete={() => deleteItem(ad)}
                    onEdit={() => navigate(`./${ad.id}`)}
                    onPlay={() =>
                      dispatch(
                        actions.updateActionDefinitionRequest({
                          id: ad.id,
                          organisation_id: organisation.id,
                          data: { ...ad, enabled: true },
                        }),
                      )
                    }
                  />
                );
              })}
            </>
          ) : (
          <Box sx={{pl:3}}>
            <Typography>You have no draft actions</Typography>
          </Box>
          )}
      </Stack>
    </Stack>
  );
}
