import React from 'react';
import { Box, MenuItem, Stack } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import key from 'weak-key';
import { get } from 'lodash';
import { NestedObject } from 'types/types';

export function OnClickFields({ fieldBase = '', onclick_actions }) {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    submitCount,
  } = useFormikContext<NestedObject>();

  const onclick: string = get(values, `${fieldBase}onclick`, '') as string;

  return (
    <Box>
      <Stack>
        <Field
          name={`${fieldBase}onclick`}
          label={'Sub-task destination'}
          component={Select}
          options={onclick_actions.map(a => a.id)}
          fullWidth
          displayEmpty
          inputLabel={{ shrink: true }}
          renderValue={v =>
            v ? onclick_actions.find(a => a.id === v)?.name : 'No action'
          }
          notched
          onChange={event => {
            const value = event.target.value;
            setFieldValue(`${fieldBase}onclick`, value);
            setFieldValue(`${fieldBase}onclick_parameters`, {});
            (
              onclick_actions.find(a => a.id === value)?.parameters || []
            ).forEach(p => {
              setFieldTouched(
                `${fieldBase}onclick_parameters.${p.field}`,
                true,
              );
            });
          }}
        >
          <MenuItem value={''}>No action </MenuItem>
          {onclick_actions.map(a => (
            <MenuItem key={key({ a })} value={a.id}>
              {a.name}
            </MenuItem>
          ))}
        </Field>
        {onclick &&
          (onclick_actions.find(a => a.id === onclick)?.parameters || []).map(
            p => {
              const fieldName = `${fieldBase}onclick_parameters.${p.field}`;
              const isTouched = get(touched, fieldName, false) || submitCount;
              const errorMessage = get(errors, fieldName);
              return (
                <Field
                  key={`${fieldBase}param.${p.field}`}
                  name={`${fieldBase}onclick_parameters.${p.field}`}
                  label={p.label}
                  placeholder={p.placeholder}
                  component={TextField}
                  fullWidth
                  value={get(
                    values,
                    `${fieldBase}onclick_parameters[${p.field}]`,
                    '',
                  )}
                  type={'text'}
                  error={!!errorMessage && isTouched}
                  helperText={(isTouched && errorMessage) || ''}
                />
              );
            },
          )}
      </Stack>
    </Box>
  );
}
