/**
 *
 * OrganisationPage
 *
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Container } from '@mui/material';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Meetings } from '../Meetings';
import { Documents } from '../DocumentsSection/Documents';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useOrganisationPageSlice } from './slice/hook';
import { selectOrganisationPage } from './slice/selectors';
import { Committees } from '../Committees';
import { Members } from '../Members';
import { Helmet } from 'react-helmet-async';
import { meetingsActions } from '../Meetings/slice';
import { documentsActions } from '../DocumentsSection/slice';
import { committeesActions } from '../Committees/slice';
import { membersActions } from '../Members/slice';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { ReportsPage } from '../ReportsPage';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { useLogUserAction } from 'app/providers/LoggingProvider/useLogUserAction';
import { get } from 'lodash';
import { OrganisationProfile } from '../OrganisationProfile';
import { Checklists } from '../Checklists';
import { organisationProfileActions } from '../OrganisationProfile/slice';
import { Dashboard } from 'app/pages/Dashboard';
import { RiskRegisterSection } from '../RiskRegisterSection';
import { riskRegisterSectionActions } from '../RiskRegisterSection/slice';
import { checklistsActions } from '../Checklists/slice';
import { PartnerDetails } from '../PartnerDetails';
import { partnerDetailsActions } from '../PartnerDetails/slice';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { Activate } from '../Activate';
import { dashboardActionsWidgetActions } from 'app/widgets/DashboardActionsWidget/slice';
import { ActionsSection } from '../ActionsSection';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';
import { SectionHeader } from 'app/components/SectionHeader';
import { AcademySeatAllocations } from '../AcademySeatAllocations';
import { DocumentsSection } from '../DocumentsSection';

interface Props {}

export function OrganisationPage(props: Props) {
  const { actions } = useOrganisationPageSlice();
  const { loadUser } = useSelector(selectAuthProvider);
  const { loadOrganisation, manageFeature } = useSelector(
    selectOrganisationPage,
  );

  const { currentOrganisationId, setCurrentOrganisationId } =
    useOrganisationSelector();

  const { hasPermission } = useHasPermission();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    init();
    setTimeout(() => setCurrentOrganisationId(+id), 100);
  }, [id]);

  /*
      LOG USER ACTIONS
     */
  const { logUserAction } = useLogUserAction();
  const loadedOrganisationId = get(loadOrganisation, 'data.id', null);
  useEffect(() => {
    if (!loadedOrganisationId) return;

    logUserAction({
      organisation_id: loadedOrganisationId,
      category: 'navigation',
      action: 'enter-organisation',
    });

    return () => {
      // log leave?
    };
  }, [loadedOrganisationId]);

  useHandleApiResponse(loadOrganisation, null, {
    errorMessage: null,
    onError: () => {
      if (
        loadOrganisation.error?.code === 403 ||
        loadOrganisation.error?.code === 401
      ) {
        navigate('/');
      }
    },
  });

  useHandleApiResponse(manageFeature, null, {
    onSuccess: () => {
      dispatch(actions.loadOrganisationRequest({ id: currentOrganisationId }));
      dispatch(authProviderActions.loadUserDataRequest());
    },
  });

  useEffect(() => {
    load();
  }, [currentOrganisationId]);

  // Use this to clear content when org is changed
  const init = () => {
    dispatch(partnerDetailsActions.loadPartnerInit());
    dispatch(meetingsActions.loadMeetingsInit());
    dispatch(documentsActions.init());
    dispatch(committeesActions.loadCommitteesInit());
    dispatch(membersActions.loadMembersInit());
    dispatch(organisationProfileActions.loadOrganisationProfileInit());
    dispatch(riskRegisterSectionActions.init());
    dispatch(checklistsActions.initOrganisationChecklists());
    dispatch(dashboardActionsWidgetActions.loadListInit());
  };

  const load = () => {
    dispatch(actions.loadOrganisationRequest({ id: +currentOrganisationId }));
    init();
  };

  const isValidOrgId = useMemo(() => {
    if (!id || !loadUser.data) return true;
    return !!loadUser.data.access_organisations.find(ao => ao.id === +id);
  }, [loadUser, id]);

  useEffect(() => {
    if (!isValidOrgId) {
      if (!loadUser.data.access_organisations.length) {
        // todo - navigate to some holding page for users with no orgs to view
        navigate(`/no-organisations`);
      } else {
        navigate(`/organisation/${loadUser.data.access_organisations[0].id}`);
      }
    }
  });

  if (!loadOrganisation.data) {
    return null;
  }

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${loadOrganisation.data.name} | Governance360`}
        defaultTitle="Dashboard"
      >
        <meta name="description" content="Organisation" />
      </Helmet>

      <>
        <Routes>
          <Route
            path={''}
            element={
              <>
                <Container>
                  <Box
                    sx={{
                      py: 2,
                    }}
                  >
                    <Dashboard organisation={loadOrganisation.data} />
                  </Box>
                </Container>
              </>
            }
          />

          {hasPermission('view-meetings', 'organisation', id) && (
            <Route
              path={'meetings/*'}
              element={
                <>
                  <Meetings organisation={loadOrganisation.data} />
                </>
              }
            />
          )}

          {hasPermission('view-actions-page', 'organisation', id) && (
            <Route
              path={'actions/*'}
              element={<ActionsSection organisation={loadOrganisation.data} />}
            />
          )}
          {hasPermission('view-committees-list', 'organisation', id) && (
            <Route
              path={'committees/*'}
              element={<Committees organisation={loadOrganisation.data} />}
            />
          )}
          {hasPermission('view-organisation-documents', 'organisation', id) && (
            <Route
              path={'documents/*'}
              element={
                <DocumentsSection organisation={loadOrganisation.data} />
              }
            />
          )}
          {hasPermission('view-organisation-reports', 'organisation', id) && (
            <Route
              path={'reports/*'}
              element={<ReportsPage organisation={loadOrganisation.data} />}
            />
          )}
          {hasPermission('view-risk-register', 'organisation', id) && (
            <Route
              path={'risk-register/*'}
              element={
                <RiskRegisterSection organisation={loadOrganisation.data} />
              }
            />
          )}

          {hasPermission('view-checklists', 'organisation', id) && (
            <Route
              path={'checklists/*'}
              element={<Checklists organisation={loadOrganisation.data} />}
            />
          )}
          {hasPermission('view-organisation-members', 'organisation', id) && (
            <Route
              path={'members/*'}
              element={<Members organisation={loadOrganisation.data} />}
            />
          )}
          {hasPermission('view-partner-details', 'organisation', id) && (
            <Route
              path={'partner-details/*'}
              element={<PartnerDetails organisation={loadOrganisation.data} />}
            />
          )}
          {hasPermission('manage-academy-seats', 'organisation', id) && (
            <Route
              path={'academy-seats/*'}
              element={
                <AcademySeatAllocations organisation={loadOrganisation.data} />
              }
            />
          )}
          {hasPermission('view-organisation-profile', 'organisation', id) && (
            <Route
              path={'organisation-profile/*'}
              element={
                <OrganisationProfile organisation={loadOrganisation.data} />
              }
            />
          )}

          <Route
            path={'activate/:code/*'}
            element={<Activate organisation={loadOrganisation.data} />}
          />
          <Route
            path={'*'}
            element={<Navigate to={`/organisation/${id}`} replace />}
          />
        </Routes>
      </>
    </>
  );
}
