import {
  Box,
  BoxProps,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { Pause, PlayArrow } from '@mui/icons-material';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { IActionDefinition } from 'types/types';
import moment from 'moment';
import { getDateWIthFallbackString } from 'utils/getDateWIthFallbackString';
import { formatParsedISO } from 'utils/formatParsedISO';

interface Props extends BoxProps {
  ad: IActionDefinition;
  isActive: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onPause?: () => void;
  onPlay?: () => void;
}

export function ActionDefinitionListItem(props: Props) {
  const { ad, onEdit, onDelete, onPause, sx, onPlay, isActive } = props;
  const theme = useTheme();
  return (
    <Box
      key={ad.id}
      sx={{
        backgroundColor: 'grey.100',
        p: 2,
        borderLeft: isActive
          ? `3px solid ${theme.palette.primary.main}`
          : `3px solid ${theme.palette.error.main}`,
        ...sx,
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        alignItems={{ xs: '', md: 'center' }}
      >
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} md={12} lg={6}>
              <Box>
                {isActive ? (
                  <Typography variant="h5"> {ad.name}</Typography>
                ) : (
                  <>
                    <Stack
                      color={theme => theme.palette.grey[600]}
                      direction={'row'}
                      alignItems={'center'}
                    >
                      <Pause titleAccess='Paused action' /> <Typography variant="h5">{ad.name}</Typography>
                    </Stack>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6} alignItems={'center'}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                alignItems={{ xs: '', sm: 'center' }}
                sx={{ px: { xs: 0, lg: 1 } }}
              >
                <Box>
                  <Typography
                    component={'span'}
                    color={theme => theme.palette.grey[600]}
                    fontWeight={600}
                  >
                    Last edited:
                  </Typography>
                  <Typography
                    component={'span'}
                    pl={1}
                    color={theme => theme.palette.grey[600]}
                  >
                    {formatParsedISO(ad.updated_at)}
                  </Typography>
                  {/* <Typography component={'span'} pl={1} color={theme => theme.palette.grey[600]}>{moment(ad.edited_at).format('D MMM Y')}</Typography> */}
                </Box>

                <Box>
                  <Typography
                    component="span"
                    color={theme => theme.palette.grey[600]}
                    fontWeight={600}
                  >
                    Completed to date:
                  </Typography>
                  <Typography
                    component={'span'}
                    pl={1}
                    color={theme => theme.palette.grey[600]}
                  >
                    {ad.completed_live_actions} of {ad.total_live_actions}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {!isActive ? <Typography>{ad.description}</Typography> : (
                  <> 
                  <Box color={theme => theme.palette.grey[600]}>
                    <Pause /> <Typography component={'span'}>{ad.description}</Typography>
                  </Box>
                  </>
                )}
              </Stack>
            </Grid> */}
          </Grid>
        </Box>

        <Box>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, md: 4 }}
            alignItems={{ xs: '', md: 'center' }}
          >
            <Button
              variant="outlined"
              fullWidth
              size="small"
              color={'error'}
              startIcon={<RiDeleteBinLine />}
              onClick={() => onDelete()}
            >
              Delete
            </Button>
            {isActive ? (
              <>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<Pause />}
                  size={'small'}
                  onClick={() => onPause()}
                  sx={{ '& .MuiButton-startIcon': { mx: 0 } }}
                />
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  startIcon={<PlayArrow titleAccess='Click to publish action' />}
                  onClick={() => onPlay()}
                  sx={{ '& .MuiButton-startIcon': { mx: 0 } }}
                />
              </>
            )}
            <Button
              variant={'outlined'}
              fullWidth
              size={'small'}
              startIcon={<RiEdit2Line />}
              onClick={() => onEdit()}
            >
              Edit
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
