/**
 *
 * ActionsSection
 *
 */
import * as React from 'react';
import { IOrganisation } from 'types/types';
import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { List } from './List';
import { Item } from './Item';

interface Props {
  organisation: IOrganisation;
}

export function ActionsManager(props: Props) {
  const { organisation } = props;

  const location = useLocation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('xs'));

  return (
    <>
      <Helmet title={'Actions Manager'}>
        <meta
          name="description"
          content="Manage your own actions on this page"
        />
      </Helmet>

      <Box sx={{ my: 4 }}>
        <Stack spacing={4}>
          <Routes>
            <Route path={''} element={<List organisation={organisation} />} />
            <Route
              path={':id'}
              element={<Item organisation={organisation} />}
            />
            <Route path={'*'} element={<Navigate to={''} replace />} />
          </Routes>
        </Stack>
      </Box>
    </>
  );
}
