import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ActionDefinitionOptionsPayload, ActionsSectionState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import {
  ActionStatus,
  IAction,
  IActionDefinition,
  IMember,
  NestedObject,
} from 'types/types';

export const initialState: ActionsSectionState = {
  loadOpenActions: getApiCallInitialState<IAction[]>('list'),
  updateAction: getApiCallInitialState<IAction>(),
  loadArchivedActions: getApiCallInitialState<IAction[]>('list'),

  loadAction: getApiCallInitialState<IAction>(),

  loadActionDefinitionOptions:
    getApiCallInitialState<ActionDefinitionOptionsPayload>('item', {
      members: [],
      date_rules: [],
      onclick_actions: [],
      roles: [],
    }),

  loadActionDefinitions: getApiCallInitialState<IActionDefinition[]>('list'),
  loadActionDefinition: getApiCallInitialState<IActionDefinition>(),
  createActionDefinition: getApiCallInitialState<IActionDefinition>(),
  updateActionDefinition: getApiCallInitialState<IActionDefinition>(),
  deleteActionDefinition: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'actionsSection',
  initialState,
  reducers: {
    init(state, action: PayloadAction) {
      return initialState;
    },

    loadOpenActionsInit(state, action: PayloadAction) {
      state.loadOpenActions = initialState.loadOpenActions;
    },
    loadOpenActionsRequest(
      state,
      action: PayloadAction<{
        organisation_id: number | string;
        searchText?: string;
      }>,
    ) {
      state.loadOpenActions = {
        ...initialState.loadOpenActions,
        data: state.loadOpenActions.data,
        loading: true,
        touched: true,
      };
    },
    loadOpenActionsSuccess(state, action: PayloadAction<{ data: IAction[] }>) {
      state.loadOpenActions = {
        ...initialState.loadOpenActions,
        data: action.payload.data,
      };
    },
    loadOpenActionsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadOpenActions = {
        ...initialState.loadOpenActions,
        error: action.payload,
      };
    },
    loadArchivedActionsInit(state, action: PayloadAction) {
      state.loadArchivedActions = initialState.loadArchivedActions;
    },
    loadArchivedActionsRequest(
      state,
      action: PayloadAction<{
        organisation_id: number | string;
        searchText?: string;
      }>,
    ) {
      state.loadArchivedActions = {
        ...initialState.loadArchivedActions,
        data: state.loadArchivedActions.data,
        loading: true,
        touched: true,
      };
    },
    loadArchivedActionsSuccess(
      state,
      action: PayloadAction<{ data: IAction[] }>,
    ) {
      state.loadArchivedActions = {
        ...initialState.loadArchivedActions,
        data: action.payload.data,
      };
    },
    loadArchivedActionsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadArchivedActions = {
        ...initialState.loadArchivedActions,
        error: action.payload,
      };
    },

    updateActionInit(state, action: PayloadAction) {
      state.updateAction = initialState.updateAction;
    },
    updateActionRequest(
      state,
      action: PayloadAction<{
        action_id: number | string;
        subtask_id?: number | string;
        clearSubtasks?: boolean;
        status: ActionStatus;
      }>,
    ) {
      state.updateAction = {
        ...initialState.updateAction,
        data: state.updateAction.data,
        loading: true,
        touched: true,
      };
    },
    updateActionSuccess(state, action: PayloadAction<{ data: IAction }>) {
      state.updateAction = {
        ...initialState.updateAction,
        data: action.payload.data,
      };
    },
    updateActionFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updateAction = {
        ...initialState.updateAction,
        error: action.payload,
      };
    },
    loadActionInit(state, action: PayloadAction) {
      state.loadAction = initialState.loadAction;
    },
    loadActionRequest(
      state,
      action: PayloadAction<{
        id: number | string;
        organisation_id: number | string;
      }>,
    ) {
      state.loadAction = {
        ...initialState.loadAction,
        data: state.loadAction.data,
        loading: true,
        touched: true,
      };
    },
    loadActionSuccess(state, action: PayloadAction<{ data: IAction }>) {
      state.loadAction = {
        ...initialState.loadAction,
        data: action.payload.data,
      };
    },
    loadActionFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadAction = {
        ...initialState.loadAction,
        error: action.payload,
      };
    },

    loadActionDefinitionOptionsInit(state, action: PayloadAction) {
      state.loadActionDefinitionOptions =
        initialState.loadActionDefinitionOptions;
    },
    loadActionDefinitionOptionsRequest(
      state,
      action: PayloadAction<{
        organisation_id: number | string;
      }>,
    ) {
      state.loadActionDefinitionOptions = {
        ...initialState.loadActionDefinitionOptions,
        data: state.loadActionDefinitionOptions.data,
        loading: true,
        touched: true,
      };
    },
    loadActionDefinitionOptionsSuccess(
      state,
      action: PayloadAction<{ data: ActionDefinitionOptionsPayload }>,
    ) {
      state.loadActionDefinitionOptions = {
        ...initialState.loadActionDefinitionOptions,
        data: action.payload.data,
      };
    },
    loadActionDefinitionOptionsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadActionDefinitionOptions = {
        ...initialState.loadActionDefinitionOptions,
        error: action.payload,
      };
    },

    loadActionDefinitionsInit(state, action: PayloadAction) {
      state.loadActionDefinitions = initialState.loadActionDefinitions;
    },
    loadActionDefinitionsRequest(
      state,
      action: PayloadAction<{ organisation_id: number | string }>,
    ) {
      state.loadActionDefinitions = {
        ...initialState.loadActionDefinitions,
        data: state.loadActionDefinitions.data,
        loading: true,
        touched: true,
      };
    },
    loadActionDefinitionsSuccess(
      state,
      action: PayloadAction<{ data: IActionDefinition[] }>,
    ) {
      state.loadActionDefinitions = {
        ...initialState.loadActionDefinitions,
        data: action.payload.data,
      };
    },
    loadActionDefinitionsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadActionDefinitions = {
        ...initialState.loadActionDefinitions,
        error: action.payload,
      };
    },
    loadActionDefinitionInit(state, action: PayloadAction) {
      state.loadActionDefinition = initialState.loadActionDefinition;
    },
    loadActionDefinitionRequest(
      state,
      action: PayloadAction<{
        id: number | string;
        organisation_id: number | string;
      }>,
    ) {
      state.loadActionDefinition = {
        ...initialState.loadActionDefinition,
        data: state.loadActionDefinition.data,
        loading: true,
        touched: true,
      };
    },
    loadActionDefinitionSuccess(
      state,
      action: PayloadAction<{ data: IActionDefinition }>,
    ) {
      state.loadActionDefinition = {
        ...initialState.loadActionDefinition,
        data: action.payload.data,
      };
    },
    loadActionDefinitionFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadActionDefinition = {
        ...initialState.loadActionDefinition,
        error: action.payload,
      };
    },

    createActionDefinitionInit(state, action: PayloadAction) {
      state.createActionDefinition = initialState.createActionDefinition;
    },
    createActionDefinitionRequest(
      state,
      action: PayloadAction<{
        data: NestedObject<IActionDefinition>;
        organisation_id: number | string;
      }>,
    ) {
      state.createActionDefinition = {
        ...initialState.createActionDefinition,
        data: state.createActionDefinition.data,
        loading: true,
        touched: true,
      };
    },

    createActionDefinitionSuccess(
      state,
      action: PayloadAction<{ data: IActionDefinition }>,
    ) {
      state.createActionDefinition = {
        ...initialState.createActionDefinition,
        data: action.payload.data,
      };
    },
    createActionDefinitionFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.createActionDefinition = {
        ...initialState.createActionDefinition,
        error: action.payload,
      };
    },

    updateActionDefinitionInit(state, action: PayloadAction) {
      state.updateActionDefinition = initialState.updateActionDefinition;
    },
    updateActionDefinitionRequest(
      state,
      action: PayloadAction<{
        id: number | string;
        organisation_id: number | string;
        data: NestedObject<IActionDefinition>;
      }>,
    ) {
      state.updateActionDefinition = {
        ...initialState.updateActionDefinition,
        data: state.updateActionDefinition.data,
        loading: true,
        touched: true,
      };
    },
    updateActionDefinitionSuccess(
      state,
      action: PayloadAction<{ data: IActionDefinition }>,
    ) {
      state.updateActionDefinition = {
        ...initialState.updateActionDefinition,
        data: action.payload.data,
      };
    },
    updateActionDefinitionFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.updateActionDefinition = {
        ...initialState.updateActionDefinition,
        error: action.payload,
      };
    },

    deleteActionDefinitionInit(state, action: PayloadAction) {
      state.deleteActionDefinition = initialState.deleteActionDefinition;
    },
    deleteActionDefinitionRequest(
      state,
      action: PayloadAction<{
        id: number | string;
        organisation_id: number | string;
      }>,
    ) {
      state.deleteActionDefinition = {
        ...initialState.deleteActionDefinition,
        loading: true,
        touched: true,
      };
    },
    deleteActionDefinitionSuccess(state, action: PayloadAction) {
      state.deleteActionDefinition = {
        ...initialState.deleteActionDefinition,
      };
    },
    deleteActionDefinitionFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.deleteActionDefinition = {
        ...initialState.deleteActionDefinition,
        error: action.payload,
      };
    },
  },
});

export const { actions: actionsSectionActions } = slice;
